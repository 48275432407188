app.controller('CmInvoiceCustomController', [
    '$scope', '$http', 'SweetAlert', '$stateParams',
    function ($scope, $http, SweetAlert, $stateParams)
    {
        $scope.params = {
            start_date: new Date(),
            end_date: new Date(),
            cm: null,
            case_id: null,
            title: ''
        };

        $scope.ready = false;

        $scope.printIt = function () {
            window.print()
        };

        $http
            .get('/case/all')
            .success(function (result)
            {
                $scope.allCases = result;
            });

        $http
            .get('/user/all')
            .success(function (result)
            {
                $scope.users = result;
            });

        $scope.searchCases = searchCases;
        $scope.selectedCase = selectedCase;
        $scope.getInvoiceData = getInvoiceData;

        function selectedCase(value)
        {
            $scope.params.case_id = value.id;
        }

        function searchCases(value)
        {
            let cases = $scope.allCases.filter(function (claim) {
                if (claim.first_name != null && claim.last_name != null) {
                    return claim.first_name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || claim.last_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                }
            });

            return cases;
        }

        function getInvoiceData()
        {
            $http
                .post('report/cm-invoice-custom', $scope.params)
                .success(function (data)
                {
                    $scope.ready = true;
                    $scope.data = data;
                }).error(genericErrorHandler);
        }

        function genericErrorHandler(error, code)
        {
            if (code == 422)
            {
                for (var property in error)
                {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else
            {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }
    }]);
