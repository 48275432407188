app.controller('UsersController', ['$scope', '$http', '$uibModal', 'DTOptionsBuilder', function ($scope, $http, $uibModal, DTOptionsBuilder) {

    $scope.dtOptions = DTOptionsBuilder.newOptions()
        .withOption('iDisplayLength', 12)
        .withOption('order', [0, 'asc'])
        .withOption('oLanguage', {'sEmptyTable': 'No users found'})
        .withBootstrap();

    $scope.createNewUser = function() {
        $uibModal.open({
            templateUrl: 'templates/users/create.html',
            controller: 'CreateUserController'
        });
    }

    $scope.editUser = function (user) {
        $uibModal.open({
            templateUrl: 'templates/users/edit.html',
            controller: 'EditUserController',
            resolve: {
                user: function () { return user; }
            }
        });
    };

    $scope.deleteUser = function (user_id) {
        if (!confirm('Are you sure you with to deactivate this user?')) return;

        $http.post('/user/delete', {id: user_id}).success(function (response) {
            alert('User deactivated');
            getUsers();
        })
    };

    function getUsers() {
        $http.get('/user/all').success(function(response) {
            $scope.users = response;
        });
    }

    $scope.$on('updated', function () {
        getUsers();
    });

    getUsers();

}]);


app.controller('CreateUserController', ['$scope', '$http', '$uibModalInstance', function ($scope, $http, $uibModalInstance) {
    $scope.user = {};

    $scope.ok = function () {
        $http.post('/user/create', $scope.user).success(function (result) {
            if (!result.success) {
                alert('Failed to create new user');
                return;
            }
            $uibModalInstance.dismiss('cancel');
            $scope.$emit('updated');
        });
    }

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    }

}]);

app.controller('EditUserController', ['$scope', '$http', '$uibModalInstance', 'user', function ($scope, $http, $uibModalInstance, user) {
    $scope.user = user;

    user.password = "";
    user.confirm_password = "";

    $scope.ok = function () {
        if (user.password && user.password !== user.confirm_password) {
            alert("passwords do not match");
            return;
        }


        $http.post('/user/edit', {user: $scope.user}).success(function (r) {
            if (r.success) {
                $scope.$emit('updated');
            } else {
                alert('Failed to update user');
                return;
            }
            $uibModalInstance.dismiss('cancel');
        });
    }

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    }

}]);
