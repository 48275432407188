app.controller('ReviewCmInvoicesController', [
    '$scope', '$http', 'SweetAlert', 'DTOptionsBuilder',
    function ($scope, $http, SweetAlert, DTOptionsBuilder)
    {
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('iDisplayLength', 25)
            .withOption('oLanguage', {'sEmptyTable': 'No Reviews found'})
            .withBootstrap();

        $http
            .get('review/generated?case_type=Case Manager')
            .success(function (reviews)
            {
                $scope.reviews = reviews;

                $scope.reviews.forEach(function (review)
                {
                    review.claim.case_information.basic_information = JSON.parse(review.claim.case_information.basic_information);
                });
            }).error(genericErrorHandler);

        function genericErrorHandler(error, code)
        {
            if (code == 422)
            {
                for (var property in error)
                {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else
            {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }
    }]);
