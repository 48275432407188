app.controller('EditCaseController', ['$location', '$scope', '$http', '$cookieStore', '$stateParams', function ($location, $scope, $http, $cookieStore, $stateParams) {

    $scope.edit = function() {
        $location.path('/contractor/edit/' + $scope.contractor.id);
    }

    $http.get('/contractor/single/' + $stateParams.id).success(function (r) {
        $scope.contractor = r;
    })

}]);