app.controller('CmInvoiceController', [
    '$scope', '$http', 'SweetAlert', '$stateParams',
    function ($scope, $http, SweetAlert, $stateParams)
    {
        $http
            .post('report/cm-invoice', {review_id: $stateParams.id})
            .success(function (data)
            {
                $scope.data = data;
                $scope.caseInfo = JSON.parse(data.review.claim.case_information.basic_information)
            }).error(genericErrorHandler);

        $scope.printIt = function () {
            window.print()
        };

        function genericErrorHandler(error, code)
        {
            if (code == 422)
            {
                for (var property in error)
                {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else
            {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }
    }]);
