app.factory('caseParser', [
    'caseFields',
    function (caseFields)
    {
        return function (rawData) {

            var parsedData = {};

            parsedData.case = rawData;

            parsedData.caseInformation = {};
            parsedData.clientProfile = {};
            parsedData.clientGoals = {};
            parsedData.preFunctionalSections = {};
            parsedData.postFunctionalSections = {};
            parsedData.clientSkills = {};
            parsedData.otInformation = {};

            console.log(caseFields);

            var i, j, k, l, fields, data, row;

            for (i in caseFields.caseInformation) {
                fields = caseFields.caseInformation[i];
                if (!rawData.case_information[i]) {
                    parsedData.caseInformation[i] = fields;
                    if (Array.isArray(fields.fields)) {
                        parsedData.caseInformation[i].fields_template = angular.merge({}, fields.fields[0]);
                    }
                    continue;
                }
                data = JSON.parse(rawData.case_information[i]);

                if (Array.isArray(fields.fields)) {
                    parsedData.caseInformation[i] = angular.merge({}, fields);
                    parsedData.caseInformation[i].fields = [];
                    parsedData.caseInformation[i].fields_template = angular.merge({}, fields.fields[0]);

                    if (data.fields.length) {
                        for (k in data.fields) {
                            row = angular.merge({}, fields.fields[0]);

                            for (l in data.fields[k]) {
                                if (row[l] && data.fields[k][l]) {
                                    row[l].value = data.fields[k][l].value;
                                }
                            }
                            parsedData.caseInformation[i].fields.push(row);
                        }
                    }
                } else {
                    parsedData.caseInformation[i] = angular.merge({}, fields);
                    for(j in fields.fields) {
                        if (data.fields[j]) {
                            parsedData.caseInformation[i].fields[j].value = data.fields[j].value;
                        }
                    }
                }
            }

            for (i in caseFields.otInformation) {
                fields = caseFields.otInformation[i];
                if (!rawData.hasOwnProperty('ot_information') || rawData.ot_information == null || !rawData.ot_information[i]) {
                    parsedData.otInformation[i] = fields;
                    if (Array.isArray(fields.fields)) {
                        parsedData.otInformation[i].fields_template = angular.merge({}, fields.fields[0]);
                    }
                    continue;
                }

                data = JSON.parse(rawData.ot_information[i]);

                if (Array.isArray(fields.fields)) {
                    parsedData.otInformation[i] = angular.merge({}, fields);
                    parsedData.otInformation[i].fields = [];
                    parsedData.otInformation[i].fields_template = angular.merge({}, fields.fields[0]);

                    if (data.fields.length) {
                        for (k in data.fields) {
                            row = angular.merge({}, fields.fields[0]);

                            for (l in data.fields[k]) {
                                if (row[l] && data.fields[k][l]) {
                                    row[l].value = data.fields[k][l].value;
                                }
                            }
                            parsedData.otInformation[i].fields.push(row);
                        }
                    }
                } else {
                    parsedData.otInformation[i] = angular.merge({}, fields);
                    for (j in data.fields) {
                        if (data.fields[j]) {
                            parsedData.otInformation[i].fields[j].value = data.fields[j].value;
                        }
                    }
                }

            }

            for (i in caseFields.clientProfile) {
                fields = caseFields.clientProfile[i];
                if (!rawData.client_profile[i])  {
                    parsedData.clientProfile[i] = fields;
                    if (Array.isArray(fields.fields)) {
                        parsedData.client_profile[i].fields_template = angular.merge({}, fields.fields[0]);
                    }
                    continue;
                }
                data = JSON.parse(rawData.client_profile[i]);

                if (Array.isArray(fields.fields)) {
                    parsedData.clientProfile[i] = angular.merge({}, fields);
                    parsedData.clientProfile[i].fields = [];
                    parsedData.clientProfile[i].fields_template = angular.merge({}, fields.fields[0]);

                    if (data.fields.length) {
                        for (k in data.fields) {
                            row = angular.merge({}, fields.fields[0]);

                            for (l in data.fields[k]) {
                                if (row[l] && data.fields[k][l]) {
                                    row[l].value = data.fields[k][l].value;
                                }
                            }
                            parsedData.clientProfile[i].fields.push(row);
                        }
                    }
                } else {
                    parsedData.clientProfile[i] = angular.merge({}, fields);
                    for(j in fields.fields) {
                        if (data.fields[j]) {
                            parsedData.clientProfile[i].fields[j].value = data.fields[j].value;
                        }
                    }
                }
            }

            for (i in caseFields.clientSkills) {
                fields = caseFields.clientSkills[i];
                if (typeof rawData.client_skills === "undefined" || !rawData.client_skills[i]) {
                    for (j in fields.fields) {
                        //If field is a grid type, create a data struct for it
                        if (fields.fields[j].type == "grid") {
                            fields.fields[j]["value"] = {};

                            for (row in fields.fields[j].rows) {
                                fields.fields[j].value[fields.fields[j].rows[row]] = [];
                                for (k = 0; k < fields.fields[j].columns.length; k++) {
                                    //With spread, you can copy the object so you dont copy pointer
                                    fields.fields[j].value[fields.fields[j].rows[row]].push({...fields.fields[j].columns[k]});
                                }
                            }
                        }
                    }
                    parsedData.clientSkills[i] = fields;
                    continue;
                } else {
                    data = JSON.parse(rawData.client_skills[i]);
                    let removeFields = ['saving', 'editing'];
                    removeFields.forEach(f => {
                        if (data.hasOwnProperty(f)) {
                            delete data[f];
                        }
                    })

                    for (let fieldKey in data.fields) {
                        if (typeof data.fields[fieldKey]['value'] === "undefined") {
                            data.fields[fieldKey]['value'] = {};
                            for (row in data.fields[fieldKey].rows) {
                                data.fields[fieldKey].value[data.fields[fieldKey].rows[row]] = [];
                                for (k = 0; k < data.fields[fieldKey].columns.length; k++) {
                                    data.fields[fieldKey].value[data.fields[fieldKey].rows[row]].push({...data.fields[fieldKey].columns[k]});
                                }
                            }
                        }
                    }

                    parsedData.clientSkills[i] = data;
                }
            }

            for (i in caseFields.clientGoals) {
                fields = caseFields.clientGoals[i];
                if (!rawData.client_goal[i])  {
                    parsedData.clientGoals[i] = fields;
                    if (Array.isArray(fields.fields)) {
                        parsedData.clientGoals[i].fields_template = angular.merge({}, fields.fields[0]);
                    }
                    continue;
                }
                data = JSON.parse(rawData.client_goal[i]);

                if (Array.isArray(fields.fields)) {
                    parsedData.clientGoals[i] = angular.merge({}, fields);
                    parsedData.clientGoals[i].fields = [];
                    parsedData.clientGoals[i].fields_template = angular.merge({}, fields.fields[0]);

                    if (data.fields.length) {
                        for (k in data.fields) {
                            row = angular.merge({}, fields.fields[0]);

                            for (l in data.fields[k]) {
                                if (row[l] && data.fields[k][l]) {
                                    row[l].value = data.fields[k][l].value;
                                }
                            }
                            parsedData.clientGoals[i].fields.push(row);
                        }
                    }
                } else {
                    parsedData.clientGoals[i] = angular.merge({}, fields);
                    for(j in fields.fields) {
                        if (data.fields[j]) {
                            parsedData.clientGoals[i].fields[j].value = data.fields[j].value;
                        }
                    }
                }
            }

            for (i in caseFields.preFunctionalSections)
            {
                parsedData.preFunctionalSections[i] = angular.merge({}, caseFields.preFunctionalSections[i]);

                if (rawData.functional_assessment && rawData.functional_assessment['pre_' + i])
                {
                    data = JSON.parse(rawData.functional_assessment['pre_' + i]);
                    if (i === 'date') {
                        parsedData.preFunctionalSections[i].value = data.value;
                    } else {
                        parsedData.preFunctionalSections[i].status = data.status;
                        parsedData.preFunctionalSections[i].comments = data.comments;
                    }
                }
            }

            for (i in caseFields.postFunctionalSections)
            {
                parsedData.postFunctionalSections[i] = angular.merge({}, caseFields.postFunctionalSections[i]);

                if (rawData.functional_assessment && rawData.functional_assessment['post_' + i])
                {
                    data = JSON.parse(rawData.functional_assessment['post_' + i]);
                    if (i === 'date') {
                        parsedData.postFunctionalSections[i].value = data.value;
                    } else {
                        parsedData.postFunctionalSections[i].status = data.status;
                        parsedData.postFunctionalSections[i].comments = data.comments;
                    }
                }
            }
            console.log(parsedData);
            return parsedData;
        }
    }
]);
