app.controller('AuditLogsController', [
'$location', '$scope', '$http', '$cookieStore', '$stateParams','Title', 'DTOptionsBuilder', 'DTColumnBuilder', '$uibModal',
function ($location, $scope, $http, $cookieStore, $stateParams, Title, DTOptionsBuilder, DTColumnBuilder, $uibModal) {

    $scope.clear = () => {
        $scope.filters = {
            type: "",
            sub_type: "",
            user: "",
            encounterStartDate: "",
            encounterEndDate: "",
            startDate: new Date(),
            endDate: new Date(),
            case_id: "",
        }

        $scope.sort = {
            encounterDate: 0,
            date: 0,
        }
    }

    $scope.toggleSort = (col, direction) => {
        console.log(col, direction);
        if ($scope.sort[col] === direction) {
            $scope.sort[col] = 0;
        } else {
            for (const key of Object.keys($scope.sort)) {
                $scope.sort[key] = 0;
            }
            $scope.sort[col] = direction;
        }
        $scope.requery();
    }

    $scope.clear();

    $http
        .get('/user/all?include_deleted=true')
        .success(function (result) {
            $scope.users = result;
        });

    $http
        .get('/case/all')
        .success(function (result) {
            $scope.allCases = result;
        })

    $scope.selectedCase = (value) => {
        $scope.filters.case_id = value.id;
    }

    $scope.searchCases = (value) => {
        let cases = $scope.allCases.filter(function (claim) {
            if (claim.first_name != null && claim.last_name != null) {
                return claim.first_name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || claim.last_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
            }
        });

        return cases;
    }

    $scope.updateFilters = () => {
        let url = "/audit-logs/filter-options";

        if ($scope.filters.type) {
            url += "?type=" + $scope.filters.type;
        }

        $http.get(url).success((res) => {
            $scope.types = res.types;
            $scope.subTypes = res.sub_types;
        }).catch((err) => {
            console.log(err);
        })

    }

    $scope.updateFilters();

    $scope.requery = () => {
        let url = "/audit-logs?";
        let parameters = [];
        console.log($scope.filters);

        if ($scope.filters.type) {
            parameters.push("type=" + $scope.filters.type);
        }

        if ($scope.filters.sub_type) {
            parameters.push("sub_type=" + $scope.filters.sub_type);
        }

        if ($scope.filters.user) {
            parameters.push("user=" + $scope.filters.user);
        }

        if ($scope.filters.encounterStartDate) {
            parameters.push("encounter_start_date=" + $scope.filters.encounterStartDate.toISOString());
        }

        if ($scope.filters.encounterEndDate) {
            parameters.push("encounter_end_date=" + $scope.filters.encounterEndDate.toISOString());
        }

        if ($scope.filters.startDate) {
            parameters.push("start_date=" + $scope.filters.startDate.toISOString());
        }

        if ($scope.filters.endDate) {
            parameters.push("end_date=" + $scope.filters.endDate.toISOString());
        }

        if ($scope.filters.case_id) {
            const name = $scope.filters.case_id.split(", ");
            for (const c of $scope.allCases) {
                if (c.first_name == name[1] && c.last_name == name[0]) {
                    parameters.push("case_id=" + c.id);
                    break;
                }
            }
        }

        $http.post(url, {
            filters: $scope.filters,
            sort: $scope.sort,
        }).success((res) => {
            $scope.auditLogs = res;
        }).catch((err) => {
            console.log(err);
            alert("Failed to fetch audit logs. ERR " + err.status + " - " + err.statusText);
        })
    }

    $scope.printName = function(log) {
        if (!log.basic_information) return "N/A";

        const info = JSON.parse(log.basic_information);

        return info.fields.first_name.value + ", " + info.fields.last_name.value;
    }

    $scope.formatLabel = function(label) {
        console.log(label);
    }

    $scope.requery();
}]);