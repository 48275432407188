app.controller('QbInvoiceController', [
    '$scope', '$stateParams', '$http', 'SweetAlert', 'DTOptionsBuilder',
    function ($scope, $stateParams, $http, SweetAlert, DTOptionsBuilder) {
        $scope.dtOptions           = new DTOptionsBuilder.newOptions();
        $scope.dtOptions.searching = false;
        $scope.dtOptions.paging    = false;

        $scope.data     = [];
        $scope.selected = {};
        $scope.start    = 0;
        $scope.date     = moment().toDate();

        $scope.generate = generate;
        $scope.mark     = mark;

        $scope.qbType = $stateParams.type == "CM" ? "review" : "invoice";
        $scope.qbTypeFull = $scope.qbType == "review" ? "Case Manager" : "Occupational Therapist";

        console.log($scope.qbType);

        refresh();

        function refresh() {
            $http
                .get('qb/list-' + $scope.qbType + "s")
                .success(function (rows) {
                    console.log(rows);
                    $scope.selected    = {};
                    $scope.data.length = 0;

                    rows.forEach(function (row) {
                        var basicInfo = JSON.parse(row.claim.case_information.basic_information);

                        $scope.data.push({
                            id: row.id,
                            name: basicInfo.fields.first_name.value + " " + basicInfo.fields.last_name.value,
                            company: row.claim.company,
                            report: row.update_number,
                            start_date: row.start,
                            end_date: row.end
                        });
                    });
                }).error(genericErrorHandler);
        }

        function generate() {
            var ids = [];

            if (!$scope.date) {
                alert('Date not properly set');
                return;
            }

            for (var i in $scope.selected) {
                if ($scope.selected[i] === 1) {
                    ids.push(i);
                }
            }


            window.open('qb/generate/' + $scope.qbType + '?' + jQuery.param({
                    id: ids,
                    start: $scope.start,
                    date: moment($scope.date).format("YYYY-MM-DD")
                }));

        }

        function mark() {

            var ids = [];

            for (var i in $scope.selected) {
                if ($scope.selected[i] === 1) {
                    ids.push(i);
                }
            }


            $http
                .post('qb/mark/' + $scope.qbType, {id: ids})
                .success(function () {
                    refresh();
                });

        }

        function genericErrorHandler(error, code) {
            if (code == 422) {
                for (var property in error) {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }
    }]);
