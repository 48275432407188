app.controller('EditAddressController', ['$scope', '$http', '$uibModalInstance', 'addressId',
    function ($scope, $http, $uibModalInstance, addressId) {

    $scope.address = {};

    $http.get('/address/single/' + addressId).success(function (address) {
        $scope.address = address;
    });

    $scope.submit = function () {
        $http.post('/address/create', $scope.address).success(function (r) {
            $uibModalInstance.close();
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    }

}]);
