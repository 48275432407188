app.constant('caseFields', {
    preFunctionalSections: {
        'date': {
            title: 'Date',
            value: null,
            type: 'date'
        },
        'medications': {
            title: 'Medications ',
            status: 'Independent',
            comments: null
        },
        'meal_prep': {
            title: 'Meal Prep ',
            status: 'Independent',
            comments: null
        },
        'eating': {
            title: 'Eating ',
            status: 'Independent',
            comments: null
        },
        'diet': {
            title: 'Diet ',
            status: 'Independent',
            comments: null
        },
        'grooming': {
            title: 'Grooming ',
            status: 'Independent',
            comments: null
        },
        'bathing': {
            title: 'Bathing ',
            status: 'Independent',
            comments: null
        },
        'dressing_upper_body': {
            title: 'Dressing - Upper Body ',
            status: 'Independent',
            comments: null
        },
        'ue_rom': {
            title: 'UE ROM ',
            status: 'Independent',
            comments: null
        },
        'dressing_lower_body': {
            title: 'Dressing - Lower Body ',
            status: 'Independent',
            comments: null
        },
        'le_rom': {
            title: 'LE ROM ',
            status: 'Independent',
            comments: null
        },
        'toileting': {
            title: 'Toileting ',
            status: 'Independent',
            comments: null
        },
        'bladder_management': {
            title: 'Bladder Management ',
            status: 'Independent',
            comments: null
        },
        'bowel_management': {
            title: 'Bowel Management ',
            status: 'Independent',
            comments: null
        },
        'comprehension_of_communication': {
            title: 'Comprehension of Communication ',
            status: 'Independent',
            comments: null
        },
        'expression': {
            title: 'Expression - Verbal, Signs, Augmentative Device ',
            status: 'Independent',
            comments: null
        },
        'social_interaction': {
            title: 'Social Interaction: (Community, Home, Work x/week) ',
            status: 'Independent',
            comments: null
        },
        'behavior_supervision': {
            title: 'Behavior / Supervision ',
            status: 'Independent',
            comments: null
        },
        'problem_solving': {
            title: 'Problem Solving ',
            status: 'Independent',
            comments: null
        },
        'hearing': {
            title: 'Hearing ',
            status: 'Independent',
            comments: null
        },
        'vision': {
            title: 'Vision ',
            status: 'Independent',
            comments: null
        },
        'locomotion_walk': {
            title: 'Locomotion - Walk ',
            status: 'Independent',
            comments: null
        },
        'locomotion_wheelchair': {
            title: 'Locomotion - Wheelchair ',
            status: 'Independent',
            comments: null
        },
        'locomotion_stairs': {
            title: 'Locomotion - Stairs ',
            status: 'Independent',
            comments: null
        },
        'transfer_bed': {
            title: 'Transfer - Bed ',
            status: 'Independent',
            comments: null
        },
        'transfer_chiar': {
            title: 'Transfer - Chair ',
            status: 'Independent',
            comments: null
        },
        'transfer_toilet': {
            title: 'Transfer - Toilet ',
            status: 'Independent',
            comments: null
        },
        'transfer_tub_shower': {
            title: 'Transfer - Tub/Shower ',
            status: 'Independent',
            comments: null
        },
        'balance': {
            title: 'Balance ',
            status: 'Independent',
            comments: null
        },
        'able_to_climb': {
            title: 'Able to Climb ',
            status: 'Independent',
            comments: null
        },
        'able_to_stoop_squat': {
            title: 'Able to Stoop / Squat ',
            status: 'Independent',
            comments: null
        },
        'able_to_kneel': {
            title: 'Able to Kneel ',
            status: 'Independent',
            comments: null
        },
        'able_to_bend_twist': {
            title: 'Able to Bend/Twist ',
            status: 'Independent',
            comments: null
        },
        'stand_length_of_time': {
            title: 'Stand - Length of Time ',
            status: 'Independent',
            comments: null
        },
        'sit_length_of_time': {
            title: 'Sit - Length of Time ',
            status: 'Independent',
            comments: null
        },
        'grip_strength': {
            title: 'Grip Strength - Equal ',
            status: 'Independent',
            comments: null
        },
        'hemiparesis': {
            title: 'Hemiparesis ',
            status: 'Independent',
            comments: null
        },
        'lift_pounds': {
            title: 'Lift - # of Pounds / Restrictions ',
            status: 'Independent',
            comments: null
        },
        'housework': {
            title: 'Housework ',
            status: 'Independent',
            comments: null
        },
        'laundry': {
            title: 'Laundry ',
            status: 'Independent',
            comments: null
        }
    },

    postFunctionalSections: {
        'date': {
            title: 'Date',
            value: null,
            type: 'date'
        },
        'medications': {
            title: 'Medications ',
            status: null,
            comments: null
        },
        'meal_prep': {
            title: 'Meal Prep ',
            status: null,
            comments: null
        },
        'eating': {
            title: 'Eating ',
            status: null,
            comments: null
        },
        'diet': {
            title: 'Diet ',
            status: null,
            comments: null
        },
        'grooming': {
            title: 'Grooming ',
            status: null,
            comments: null
        },
        'bathing': {
            title: 'Bathing ',
            status: null,
            comments: null
        },
        'dressing_upper_body': {
            title: 'Dressing - Upper Body ',
            status: null,
            comments: null
        },
        'ue_rom': {
            title: 'UE ROM ',
            status: null,
            comments: null
        },
        'dressing_lower_body': {
            title: 'Dressing - Lower Body ',
            status: null,
            comments: null
        },
        'le_rom': {
            title: 'LE ROM ',
            status: null,
            comments: null
        },
        'toileting': {
            title: 'Toileting ',
            status: null,
            comments: null
        },
        'bladder_management': {
            title: 'Bladder Management ',
            status: null,
            comments: null
        },
        'bowel_management': {
            title: 'Bowel Management ',
            status: null,
            comments: null
        },
        'comprehension_of_communication': {
            title: 'Comprehension of Communication ',
            status: null,
            comments: null
        },
        'expression': {
            title: 'Expression - Verbal, Signs, Augmentative Device ',
            status: null,
            comments: null
        },
        'social_interaction': {
            title: 'Social Interaction: (Community, Home, Work x/week) ',
            status: null,
            comments: null
        },
        'behavior_supervision': {
            title: 'Behavior / Supervision ',
            status: null,
            comments: null
        },
        'problem_solving': {
            title: 'Problem Solving ',
            status: null,
            comments: null
        },
        'hearing': {
            title: 'Hearing ',
            status: null,
            comments: null
        },
        'vision': {
            title: 'Vision ',
            status: null,
            comments: null
        },
        'locomotion_walk': {
            title: 'Locomotion - Walk ',
            status: null,
            comments: null
        },
        'locomotion_wheelchair': {
            title: 'Locomotion - Wheelchair ',
            status: null,
            comments: null
        },
        'locomotion_stairs': {
            title: 'Locomotion - Stairs ',
            status: null,
            comments: null
        },
        'transfer_bed': {
            title: 'Transfer - Bed ',
            status: null,
            comments: null
        },
        'transfer_chiar': {
            title: 'Transfer - Chair ',
            status: null,
            comments: null
        },
        'transfer_toilet': {
            title: 'Transfer - Toilet ',
            status: 'Independent',
            comments: null
        },
        'transfer_tub_shower': {
            title: 'Transfer - Tub/Shower ',
            status: null,
            comments: null
        },
        'balance': {
            title: 'Balance ',
            status: null,
            comments: null
        },
        'able_to_climb': {
            title: 'Able to Climb ',
            status: null,
            comments: null
        },
        'able_to_stoop_squat': {
            title: 'Able to Stoop / Squat ',
            status: null,
            comments: null
        },
        'able_to_kneel': {
            title: 'Able to Kneel ',
            status: null,
            comments: null
        },
        'able_to_bend_twist': {
            title: 'Able to Bend/Twist ',
            status: null,
            comments: null
        },
        'stand_length_of_time': {
            title: 'Stand - Length of Time ',
            status: null,
            comments: null
        },
        'sit_length_of_time': {
            title: 'Sit - Length of Time ',
            status: null,
            comments: null
        },
        'grip_strength': {
            title: 'Grip Strength - Equal ',
            status: null,
            comments: null
        },
        'hemiparesis': {
            title: 'Hemiparesis ',
            status: null,
            comments: null
        },
        'lift_pounds': {
            title: 'Lift - # of Pounds / Restrictions ',
            status: null,
            comments: null
        },
        'housework': {
            title: 'Housework ',
            status: null,
            comments: null
        },
        'laundry': {
            title: 'Laundry ',
            status: null,
            comments: null
        }
    },


    caseInformation: {
        basic_information: {
            title: 'Basic Information',
            fields: {
                first_name: {
                    name: 'First Name',
                    type: 'textbox',
                    value: null,
                    allowEmpty: false
                },
                middle_name: {
                    name: 'Middle Name',
                    type: 'textbox',
                    value: null
                },
                last_name: {
                    name: 'Last Name',
                    type: 'textbox',
                    value: null,
                    allowEmpty: false
                },
                date_of_birth: {
                    name: 'Date of Birth',
                    type: 'date',
                    value: null
                },
                race: {
                    name: 'Race',
                    type: 'textbox',
                    value: null
                },
                gender: {
                    name: 'Gender',
                    type: 'dropdown',
                    options: ['Male', 'Female'],
                    value: null
                },
                marital_status: {
                    name: 'Marital Status',
                    type: 'dropdown',
                    options: ['Single', 'Married', 'Divorced', 'Widowed'],
                    value: null
                },
                case_status: {
                    name: 'Case Status',
                    type: 'dropdown',
                    options: ['Open', 'Closed'],
                    value: null
                },
                case_types: {
                    name: "Case Type(s)",
                    type: "checkbox-array",
                    options: ["Case Manager", "Occupational Therapist"],
                    value: ["Case Manager"],
                    allowEmpty: false
                },
                primary_case_manager: {
                    name: 'Primary Case Manager',
                    type: 'user',
                    value: null
                },
                primary_case_coordinator: {
                    name: 'Primary Case Coordinator',
                    type: 'user',
                    value: null
                },
                comments: {
                    name: "Comments",
                    type: "text",
                    value: null
                }
            }
        },
        addresses: {
            title: 'Addresses',
            fields: [{
                address_1: {
                    name: 'Address',
                    type: 'textbox',
                    value: null
                },
                address_2: {
                    name: 'Address 2',
                    type: 'textbox',
                    value: null
                },
                city: {
                    name: 'City',
                    type: 'textbox',
                    value: null
                },
                state: {
                    name: 'State',
                    type: 'textbox',
                    value: null
                },
                zip: {
                    name: 'Zip',
                    type: 'textbox',
                    value: null
                }
            }]
        },
        phone_numbers: {
            title: 'Phone Numbers',
            fields: {
                home: {
                    name: 'Home',
                    type: 'phone',
                    value: null
                },
                cell: {
                    name: 'Cell',
                    type: 'phone',
                    value: null
                },
                work: {
                    name: 'Work',
                    type: 'phone',
                    value: null
                },
                fax: {
                    name: 'Fax',
                    type: 'phone',
                    value: null
                },
                alt1: {
                    name: 'Alternate 1',
                    type: 'textbox',
                    value: null
                },
                alt2: {
                    name: 'Alternate 2',
                    type: 'textbox',
                    value: null
                }
            }
        },
        referral_information: {
            title: 'Referral Information',
            no_update: true,
            fields: [{
                dor: {
                    name: 'DOR',
                    type: 'date',
                    value: null
                },
                doi: {
                    name: 'DOI',
                    type: 'date',
                    value: null
                },
                referral_source: {
                    name: 'Referral Source',
                    type: 'textbox',
                    value: null
                }
            }],
        },
        is_own_guardian: {
            title: 'Is Own Guardian',
            no_update: false,
            fields: {
                is_own_guardian: {
                    name: 'Is Own Guardian',
                    type: 'checkbox',
                    value: null
                }
            }
        },
        guardians: {
            title: 'Guardian/Conservator/Other',
            no_update: true,
            fields: [{
                name: {
                    name: 'Name',
                    type: 'textbox',
                    value: null
                },
                relationship: {
                    name: 'Relationship',
                    type: 'textbox',
                    value: null
                },
                date_of_birth: {
                    name: 'Date of Birth',
                    type: 'date',
                    value: null
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null
                },
                email: {
                    name: 'Email',
                    type: 'textbox',
                    value: null
                },
                address: {
                    name: 'Address',
                    type: 'textbox',
                    value: null
                },
                city: {
                    name: 'City',
                    type: 'textbox',
                    value: null
                },
                state: {
                    name: 'State',
                    type: 'textbox',
                    value: null
                },
                zip: {
                    name: 'Zip',
                    type: 'textbox',
                    value: null
                }
            }]
        },
        spouse: {
            title: 'Spouse',
            no_update: true,
            fields: {
                name: {
                    name: 'Spouse Name',
                    type: 'textbox',
                    value: null
                },
                date_of_birth: {
                    name: 'Date of Birth',
                    type: 'date',
                    value: null
                }
            }
        },
        children: {
            title: 'Children',
            no_update: true,
            fields: [{
                name: {
                    name: 'Childs Name',
                    type: 'textbox',
                    value: null
                },
                date_of_birth: {
                    name: 'Date of Birth',
                    type: 'textbox',
                    value: null
                }
            }]
        },
        auto_insurance_information: {
            title: 'Auto Insurance Information',
            fields: [{
                adjuster_company: {
                    name: 'Adjuster / Company',
                    type: 'addressBook',
                    value: null
                },
                claim_num: {
                    name: 'Claim #',
                    type: 'textbox',
                    value: null
                },
                date_contacted: {
                    name: 'Date Contacted',
                    type: 'date',
                    value: null
                },
                additional_information: {
                    name: 'Additional Information',
                    type: 'text',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                },
                primary: {
                    name: 'Primary',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        health_insurance_information: {
            title: 'Health Insurance Information',
            fields: [{
                company: {
                    name: 'Company',
                    type: 'addressBook',
                    value: null
                },
                effective_date: {
                    name: 'Effective Date',
                    type: 'textbox',
                    value: null
                },
                contact: {
                    name: 'Contact',
                    type: 'textbox',
                    value: null
                },
                subscriber: {
                    name: 'Subscriber',
                    type: 'textbox',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                },
                additional_information: {
                    name: 'Additional Information',
                    type: 'text',
                    value: null
                },
                primary: {
                    name: 'Primary',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        other_gap_insurance_information: {
            title: 'Other / GAP Insurance Information',
            fields: [{
                company: {
                    name: 'Company',
                    type: 'addressBook',
                    value: null
                },
                effective_date: {
                    name: 'Effective Date',
                    type: 'textbox',
                    value: null
                },
                contact: {
                    name: 'Contact',
                    type: 'textbox',
                    value: null
                },
                subscriber: {
                    name: 'Subscriber',
                    type: 'textbox',
                    value: null
                },
                policy_num: {
                    name: 'Policy #',
                    type: 'textbox',
                    value: null
                },
                group_num: {
                    name: 'Group #',
                    type: 'textbox',
                    value: null
                },
                plan_num: {
                    name: 'Plan #',
                    type: 'textbox',
                    value: null
                },
                workers_comp: {
                    name: 'Worker\'s Comp',
                    type: 'checkbox',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }]
        },
        attorney_information: {
            title: 'Attorney Information',
            fields: [{
                attorney: {
                    name: 'Attorney',
                    type: 'addressBook',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }]
        },
        additional_contacts: {
            title: 'Additional Contacts',
            no_update: true,
            fields: [{
                type: {
                    name: 'Type',
                    type: 'dropdown',
                    options: ['Spouse', 'Guardian', 'Conservator', 'POA'],
                    value: null
                },
                name: {
                    name: 'Name',
                    type: 'textbox',
                    value: null
                },
                dob: {
                    name: 'DOB',
                    type: 'date',
                    value: null
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null
                },
                address: {
                    name: 'Address',
                    type: 'textbox',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }]
        },
        rate: {
            title: 'Rate',
            no_update: true,
            fields: {
                case_management_rate: {
                    name: "Assigned Case Management Rate",
                    type: "textbox",
                    value: 115
                },
                occupational_therapy_rate: {
                    name: "Assigned Occupational Therapist Rate",
                    type: "textbox",
                    value: 175
                }
            }
        },
        medical_requests: {
            title: 'Medical Requests',
            no_update: true,
            fields: [{
                provider: {
                    name: 'Provider',
                    type: 'addressBook',
                    value: null
                },
                requester: {
                    name: 'Requester',
                    type: 'user',
                    value: null
                },
                details: {
                    name: 'Details',
                    type: 'text',
                    value: null
                },
                date_requested: {
                    name: 'Date Requested',
                    type: 'date',
                    value: null
                },
                is_stat: {
                    name: "Stat",
                    type: "checkbox",
                    value: false
                },
                date_of_service: {
                    name: 'Date of Service',
                    type: 'date',
                    value: null
                },
                date_faxed: {
                    name: 'Date Faxed',
                    type: 'date',
                    value: null
                },
                date_received: {
                    name: 'Date Received',
                    type: 'date',
                    value: null
                },
                not_received: {
                    name: 'Failed to Receive',
                    type: 'checkbox',
                    value: false
                },
                cost: {
                    name: 'Cost',
                    type: 'number',
                    value: null
                },
                not_received_reason: {
                    name: 'Not Received Reason',
                    type: 'text',
                    value: null
                }
            }]
        }

    },

    clientProfile: {
        sources_of_income: {
            title: 'Sources of Income',
            fields: [{
                type: {
                    name: 'Type',
                    type: 'dropdown',
                    options: ['VA', 'WC', 'DVR', 'SS Retirement', 'SSDI', 'Medicaid', 'Medicare', 'Wages', 'Annuity', 'Other: Lost Wages', 'Other'],
                    value: null

                },
                amount: {
                    name: 'Amount',
                    type: 'textbox',
                    value: null
                },
                per: {
                    name: 'Per',
                    type: 'textbox',
                    value: null
                }
            }]
        },
        other_agency_involvement: {
            title: 'Other Agency Involvement',
            fields: [{
                agency: {
                    name: 'Agency',
                    type: 'dropdown',
                    options: ['State Vocational Rehab', 'State Employment Services', 'Rehab Nurse', 'Other'],
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }]
        },
        childhood_diagnosis: {
            title: 'Childhood Diagnosis',
            fields: [{
                diagnosis_and_age: {
                    name: 'Diagnosis & Age',
                    type: 'text',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }]
        },
        vaccines: {
            title: 'Vaccines',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        interests_prior: {
            title: 'Interests Prior to Injury',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        interests_after: {
            title: 'Interests After Injury',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        history_of_injury: {
            title: 'History of Injury',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        medical_status: {
            title: 'Medical Status',
            fields: {
                pre_morbid_diagnosis: {
                    name: 'Pre-Morbid Diagnosis',
                    type: 'text',
                    value: null
                },
                mva_related_diagnosis: {
                    name: 'MVA Related Diagnosis',
                    type: 'text',
                    value: null
                },
                pre_morbid_surgeries: {
                    name: 'Pre-Morbid Surgeries',
                    type: 'text',
                    value: null
                },
                mva_related_surgeries: {
                    name: 'MVA Related Surgeries',
                    type: 'text',
                    value: null
                },
                current_medical_status: {
                    name: 'Current Medical Status',
                    type: 'text',
                    value: null
                }
            }
        },
        introduction: {
            title: 'Introduction',
            fields: {
                introduction: {
                    name: 'Introduction',
                    type: 'text',
                    value: null
                }
            }
        },
        client_profile: {
            title: 'Client Profile',
            fields: {
                race: {
                    name: 'Race',
                    type: 'textbox',
                    value: null
                },
                height: {
                    name: 'Height',
                    type: 'textbox',
                    value: null
                },
                weight: {
                    name: 'Weight',
                    type: 'textbox',
                    value: null
                },
                dominant_hand: {
                    name: 'Dominant Hand',
                    type: 'dropdown',
                    options: ['Left', 'Right'],
                    value: null
                },
                glasses: {
                    name: 'Glasses',
                    type: 'textbox',
                    value: null
                },
                contact_lenses: {
                    name: 'Contact Lenses',
                    type: 'checkbox',
                    value: null
                },
                education_school: {
                    name: 'Education / School',
                    type: 'text',
                    value: null
                },
                work_history: {
                    name: 'Work History',
                    type: 'text',
                    value: null
                },
                vehicle: {
                    name: 'Vehicle',
                    type: 'text',
                    value: null
                },
                transportation: {
                    name: 'Transportation',
                    type: 'text',
                    value: null
                },
                driving: {
                    name: 'Driving',
                    type: 'text',
                    value: null
                },
                surgeries_dates: {
                    name: 'Surgeries & Dates',
                    type: 'text',
                    value: null
                },
                living_arrangements: {
                    name: 'Living Arrangements',
                    type: 'text',
                    value: null
                },
                psychological: {
                    name: 'Psychological',
                    type: 'text',
                    value: null
                },
                respiratory_status: {
                    name: 'Respiratory Status',
                    type: 'text',
                    value: null
                },
                sleep_arises: {
                    name: 'Sleep Arises',
                    type: 'textbox',
                    value: null
                },
                sleep_retires: {
                    name: 'Sleep Retires',
                    type: 'textbox',
                    value: null
                },
                avg_hrs_of_sleep: {
                    name: 'Avg Hrs of Sleep (per 24)',
                    type: 'textbox',
                    value: null
                },
                sleep_difficulties: {
                    name: 'Sleep Difficulties',
                    type: 'text',
                    value: null
                },
                physical_stamina: {
                    name: 'Physical Stamina',
                    type: 'text',
                    value: null
                },
                pain: {
                    name: 'Pain',
                    type: 'text',
                    value: null
                },
                headaches: {
                    name: 'Headaches',
                    type: 'text',
                    value: null
                },
                seizures: {
                    name: 'Seizures',
                    type: 'text',
                    value: null
                },
                feeling: {
                    name: 'Feeling',
                    type: 'text',
                    value: null
                },
                open_wounds: {
                    name: 'Open Wounds or Sores',
                    type: 'text',
                    value: null
                },
                excessive_tone: {
                    name: 'Excessive Tone',
                    type: 'text',
                    value: null
                },
                emotional_status: {
                    name: 'Emotional Status',
                    type: 'text',
                    value: null
                }
            }
        },
        providers_review_date: {
            title: 'Providers Review Date',
            fields: {
                review_date: {
                    name: 'Review Date',
                    type: 'date',
                    value: null
                }
            }
        },
        providers: {
            title: 'Providers',
            fields: [{
                provider: {
                    name: 'Provider',
                    type: 'addressBook',
                    value: null
                },
                speciality: {
                    name: 'Specialty',
                    type: 'textbox',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        therapists_review_date: {
            title: 'Therapists Review Date',
            fields: {
                review_date: {
                    name: 'Review Date',
                    type: 'date',
                    value: null
                }
            }
        },
        physical_therapists: {
            title: 'Physical Therapists',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null,
                },
                contact_name_facility: {
                    name: 'Contact Name / Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        occupational_therapists: {
            title: 'Occupational Therapists',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null,
                },
                contact_name_facility: {
                    name: 'Contact Name / Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        slp_therapists: {
            title: 'SLP Therapists',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name / Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        fitness_therapists: {
            title: 'Fitness Therapists',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name / Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        massage_therapists: {
            title: 'Massage Therapists',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name / Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        psychologist_information: {
            title: 'Psychologist Information',
            fields: [{
                review_date: {
                    name: 'Review Date',
                    type: 'date',
                    value: null
                },
                psychological_testing_date: {
                    name: 'Psychological Testing Date',
                    type: 'date',
                    value: null
                },
                tested_by: {
                    name: 'Tested By',
                    type: 'textbox',
                    value: null
                },
                treatment: {
                    name: 'Treatment',
                    type: 'textbox',
                    value: null
                }
            }]
        },
        psychologists: {
            title: 'Psychologists',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name/Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        behavioral_therapists: {
            title: 'Behavioral/Cognitive Therapists',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name/Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        vocational_therapists: {
            title: 'Vocational Therapists',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name/Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        social_work: {
            title: 'Social Work',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name/Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        vision_therapy: {
            title: 'Vision Therapy',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name/Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        recreational_therapy: {
            title: 'Recreational Therapy',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name/Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        art_therapy: {
            title: 'Art Therapy',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name/Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        music_therapy: {
            title: 'Music Therapy',
            fields: [{
                date_started: {
                    name: 'Date Started',
                    type: 'textbox',
                    value: null
                },
                date_ended: {
                    name: 'Date Ended',
                    type: 'textbox',
                    value: null
                },
                facility: {
                    name: 'Facility',
                    type: 'addressBook',
                    value: null
                },
                contact_name_facility: {
                    name: 'Contact Name/Facility',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                fax: {
                    name: 'Fax',
                    type: 'textbox',
                    value: null,
                    hidden: true
                },
                duration_frequency: {
                    name: 'Duration / Frequency',
                    type: 'textbox',
                    value: null
                },
                rx_by: {
                    name: 'Rx By',
                    type: 'textbox',
                    value: null
                },
                discontinued: {
                    name: 'Discontinued',
                    type: 'checkbox',
                    value: null
                }
            }]
        },
        durable_medical_equipment_review_date: {
            title: 'Durable Medical Equipment Review Date',
            fields: {
                review_date: {
                    name: 'Review Date',
                    type: 'date',
                    value: null
                }
            }
        },
        durable_medical_equipment: {
            title: 'Durable Medical Equipment',
            fields: [{
                dme: {
                    name: 'DME',
                    type: 'textbox',
                    value: null
                },
                company: {
                    name: 'Company',
                    type: 'addressBook',
                    value: null
                },
                date_purchased: {
                    name: 'Date Purchased',
                    type: 'textbox',
                    value: null
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null
                },
                physician: {
                    name: 'Physician',
                    type: 'textbox',
                    value: null
                },
                purchase_or_rental: {
                    name: 'Purchased or Rented',
                    type: 'dropdown',
                    value: null,
                    options: ['Purchased', 'Rented']
                }
            }]
        },
        supplies: {
            title: 'Supplies',
            fields: [{
                supplies: {
                    name: 'Supplies',
                    type: 'textbox',
                    value: null
                },
                company: {
                    name: 'Company',
                    type: 'addressBook',
                    value: null
                },
                date_purchased: {
                    name: 'Date Ordered',
                    type: 'textbox',
                    value: null
                },
                phone: {
                    name: 'Phone',
                    type: 'textbox',
                    value: null
                },
                physician: {
                    name: 'Physician',
                    type: 'textbox',
                    value: null
                }
            }]
        },
        home_modifications: {
            title: 'Home Modifications',
            fields: {
                review_date: {
                    name: 'Review Date',
                    type: 'date',
                    value: null
                },
                bathroom_mods: {
                    name: 'Bathroom Mods',
                    type: 'textbox',
                    value: null
                },
                kitchen_mods: {
                    name: 'Kitchen Mods',
                    type: 'textbox',
                    value: null
                },
                bedroom_mods: {
                    name: 'Bedroom Mods',
                    type: 'textbox',
                    value: null
                },
                door_frames: {
                    name: 'Door Frame(s)',
                    type: 'textbox',
                    value: null
                },
                ramps: {
                    name: 'Ramp(s)',
                    type: 'textbox',
                    value: null
                },
                contractor: {
                    name: 'Contractor',
                    type: 'addressBook',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        home_health_care: {
            title: 'Home Health Care',
            fields: {
                review_date: {
                    name: "Review Date",
                    type: 'date',
                    value: null
                },
                level_of_care: {
                    name: 'Level of Care',
                    type: 'text',
                    value: null
                },
                hours_of_care: {
                    name: 'Hours of Care/day/week',
                    type: 'text',
                    value: null
                },
                family_provides: {
                    name: 'Family Provides',
                    type: 'checkbox',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                },
                ordering_physician: {
                    name: 'Ordering Physician',
                    type: 'addressBook',
                    value: null
                }
            }
        },
        home_health_care_agents: {
            title: 'Home Health Care Agency',
            fields: [{
                agent: {
                    name: 'Agency',
                    type: 'addressBook',
                    value: null
                }
            }]
        },
        nursing_charges: {
            title: 'Nursing Charges',
            fields: [{
                skill_level: {
                    name: 'Skill Level',
                    type: 'dropdown',
                    value: null,
                    options: ['RN', 'LPN']
                },
                charge_hour: {
                    name: 'Charge/Hour',
                    type: 'textbox',
                    value: null
                },
                charge_visit: {
                    name: 'Charge/Visit',
                    type: 'textbox',
                    value: null
                },
                agency: {
                    name: 'Agency Name/Contact',
                    type: 'textbox',
                    value: null
                }
            }]
        },
        nursing_duties: {
            title: 'Nursing Duties',
            fields: [{
                duties: {
                    name: 'Duties (1 per entry)',
                    type: 'textbox',
                    value: null
                }
            }]
        },
        aid_charges: {
            title: 'Aide Charges',
            fields: [{
                skill_level: {
                    name: 'Skill Level',
                    type: 'dropdown',
                    value: null,
                    options: ['CNA', 'HHA', 'Other']
                },
                charge_hour: {
                    name: 'Charge/Hour',
                    type: 'textbox',
                    value: null
                },
                charge_visit: {
                    name: 'Charge/Visit',
                    type: 'textbox',
                    value: null
                },
                agency: {
                    name: 'Agency Name/Contact',
                    type: 'textbox',
                    value: null
                }

            }]
        },
        aid_duties: {
            title: 'Aide Duties',
            fields: [{
                duties: {
                    name: 'Duties (1 per entry)',
                    type: 'text',
                    value: null
                }
            }]
        },
        household_replacement_services: {
            title: 'Household Replacement Services',
            fields: {
                review_date: {
                    name: 'Review Date',
                    type: 'date',
                    value: null
                },
                list_of_duties: {
                    name: 'List of Duties',
                    type: 'text',
                    value: null
                },
                list_of_providers: {
                    name: 'List of Providers',
                    type: 'text',
                    value: null
                }
            }
        },
        transportation: {
            title: 'Transportation',
            fields: {
                review_date: {
                    name: 'Review Date',
                    type: 'date',
                    value: null
                },
                transportation_company: {
                    name: 'Transportation Company',
                    type: 'addressBook',
                    value: null
                },
                phone_number: {
                    name: 'Phone Number',
                    type: 'textbox',
                    value: null
                },
                type_of_vehicle: {
                    name: 'Type of Vehicle',
                    type: 'textbox',
                    value: null
                },
                cost_involved_per_pickup: {
                    name: 'Cost Involved Per Pick Up',
                    type: 'textbox',
                    value: null
                },
                cost_involved_per_mile: {
                    name: 'Cost Involved Per Mile',
                    type: 'textbox',
                    value: null
                },
                client_owned_vehicle: {
                    name: 'Client Owned Vehicle',
                    type: 'title',
                    value: null
                },
                model: {
                    name: 'Model',
                    type: 'textbox',
                    value: null
                },
                year: {
                    name: 'Year',
                    type: 'textbox',
                    value: null
                },
                date_purchased: {
                    name: 'Date Purchased',
                    type: 'textbox',
                    value: null
                },
                miles: {
                    name: 'Miles',
                    type: 'textbox',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        medication_review_date: {
            title: 'Medication List Review Date',
            fields: {
                review_date: {
                    name: 'Review Date',
                    type: 'date',
                    value: null
                }
            }
        },
        pre_medications: {
            title: 'Pre-Injury Medications',
            fields: [{
                medication: {
                    name: 'Medication',
                    type: 'textbox',
                    value: null
                },
                dose_frequency: {
                    name: 'Dose / Frequency',
                    type: 'textbox',
                    value: null
                },
                dr_prescribed: {
                    name: 'Dr. Prescribed',
                    type: 'textbox',
                    value: null
                },
                date_prescribed: {
                    name: 'Date Prescribed',
                    type: 'textbox',
                    value: null
                },
                purpose: {
                    name: 'Purpose',
                    type: 'textbox',
                    value: null
                },
                status: {
                    name: 'Status',
                    type: 'dropdown',
                    value: null,
                    options: ['Active', 'Discontinued']
                }
            }]
        },
        post_medications: {
            title: 'Post-Injury Medications',
            fields: [{
                medication: {
                    name: 'Medication',
                    type: 'textbox',
                    value: null
                },
                dose_frequency: {
                    name: 'Dose / Frequency',
                    type: 'textbox',
                    value: null
                },
                dr_prescribed: {
                    name: 'Dr. Prescribed',
                    type: 'textbox',
                    value: null
                },
                date_prescribed: {
                    name: 'Date Prescribed',
                    type: 'textbox',
                    value: null
                },
                purpose: {
                    name: 'Purpose',
                    type: 'textbox',
                    value: null
                },
                status: {
                    name: 'Status',
                    type: 'dropdown',
                    value: null,
                    options: ['Active', 'Discontinued']
                }
            }]
        },
        prn_medications: {
            title: 'PRN Medications (Post Injury)',
            fields: [{
                medication: {
                    name: 'Medication',
                    type: 'textbox',
                    value: null
                },
                dose_frequency: {
                    name: 'Dose / Frequency',
                    type: 'textbox',
                    value: null
                },
                dr_prescribed: {
                    name: 'Dr. Prescribed',
                    type: 'textbox',
                    value: null
                },
                date_prescribed: {
                    name: 'Date Prescribed',
                    type: 'textbox',
                    value: null
                },
                purpose: {
                    name: 'Purpose',
                    type: 'textbox',
                    value: null
                },
                status: {
                    name: 'Status',
                    type: 'dropdown',
                    value: null,
                    options: ['Active', 'Discontinued']
                }
            }]
        },
        allergies: {
            title: 'Allergies',
            fields: {
                medication: {
                    name: 'Medication',
                    type: 'text',
                    value: null
                },
                food: {
                    name: 'Food',
                    type: 'text',
                    value: null
                },
                environmental: {
                    name: 'Environmental',
                    type: 'text',
                    value: null
                },
                other: {
                    name: '(i.e: Latex, Metal, re: MRI / CT, etc)',
                    type: 'text',
                    value: null
                },
            }
        },
        pharmacies: {
            title: 'Pharmacies',
            fields: [{
                pharmacy: {
                    name: 'Pharmacy',
                    type: 'addressBook',
                    value: null
                },
                contact: {
                    name: 'Contact',
                    type: 'textbox',
                    value: null
                },
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }]
        },
        items_to_complete: {
            title: 'Items Which Need to be Completed, Scheduled, etc',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        medication_statement: {
            title: 'Medication Statement',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: 'Please see attached for a complete list of current medications.'
                }
            }
        }
    },

    clientGoals: {
        rx: {
            title: 'Rx for Case Management Services',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        date_prescribed: {
            title: 'Date Prescribed',
            fields: {
                comments: {
                    name: 'Date',
                    type: 'text',
                    value: null
                }
            }
        },
        long_term_date: {
            title: 'Client Long Term Goal Date',
            fields: {
                date: {
                    name: 'Date',
                    type: 'date',
                    value: null
                }
            }
        },
        long_term_goal: {
            title: 'Client Long Term Goal',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        short_term_date: {
            title: 'Client Short Term Goal Date',
            fields: {
                date: {
                    name: 'Date',
                    type: 'date',
                    value: null
                }
            }
        },
        short_term_goal: {
            title: 'Client Short Term Goal',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        inability_to_care: {
            title: 'Inability To Care For Self',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        skin_breakdown: {
            title: 'Skin Breakdown',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        cognition: {
            title: 'Cognition',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        safety_and_potential_additional_injury: {
            title: 'Safety and Potential for Additional Injury',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        pain: {
            title: 'Pain',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        depression: {
            title: 'Depression',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        nutrition: {
            title: 'Nutrition',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        transportation: {
            title: 'Transportation',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        medication: {
            title: 'Medication',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        mobility: {
            title: 'Mobility',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        activity: {
            title: 'Activity',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        respiratory: {
            title: 'Respiratory',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        urinary_functional_status: {
            title: 'Urinary Functional Status',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        bowel_function: {
            title: 'Bowel Function',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        },
        general_care: {
            title: 'General Care / Health Maintenance Issues',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: "Maintain contact with all treating professionals in order to case manage CLIENT'S NAME needs. Use of the following in the case management process to achieve desired rehab. outcomes - collaboration, assessment, planning, implementation, coordination, monitoring, and evaluation of options and services. The above methods will be used on an ongoing basis to meet CLIENT'S NAME individual, rehab. and health needs in addition to using communication and available resources to promote quality cost-effective outcomes."
                }
            }
        },
        summary_this_period: {
            title: 'Summary For This Reporting Period',
            fields: {
                comments: {
                    name: 'Comments',
                    type: 'text',
                    value: null
                }
            }
        }
    },
    clientSkills: {
        mobility: {
            title: "Mobility Skills",
            fields: {
                grid: {
                    type: "grid",
                    name: "Mobility Skills",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "Wheelchair Prep.",
                        "Mobility in Open Areas",
                        "Through Bedroom Doorway",
                        "Bathroom Doorway",
                        "Kitchen Doorway",
                        "Other"
                    ]
                }
            }
        },
        bed: {
            title: "Transfers / Bed Mobility",
            fields: {
                grid: {
                    type: "grid",
                    name: "Transfers / Bed Mobility",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "Bed",
                        "Commode / Adapted Commode",
                        "Bathtub / Bath Seat",
                        "Couch / Chair",
                        "Bed Mobility"
                    ]
                }
            }
        },
        adl: {
            title: "Basic ADL Skills",
            fields: {
                grid: {
                    type: "grid",
                    name: "Basic ADL Skills",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "UE Dressing",
                        "LE Dressing",
                        "Bathing / Showering",
                        "Dental Care",
                        "Hair Care"
                    ]
                }
            }
        },
        feeding_self: {
            title: "Feeding Self",
            fields: {
                grid: {
                    type: "grid",
                    name: "Feeding Self",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "Feeding Self"
                    ]
                }
            }
        },
        medication_management: {
            title: "Medication Management",
            fields: {
                grid: {
                    type: "grid",
                    name: "Medication Management",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "Medication Management"
                    ]
                }
            }
        },
        medical_equipment: {
            title: "Assist with Medical Equipment",
            fields: {
                grid: {
                    type: "grid",
                    name: "Assist with Medical Equipment",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "Assist with Medical Equipment"
                    ]
                }
            }
        },
        kitchen_accessibility: {
            title: "Kitchen Accessibility",
            fields: {
                grid: {
                    type: "grid",
                    name: "Kitchen Accessibility",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "Stove",
                        "Microwave",
                        "Refrigerator / Freezer",
                        "Sink",
                        "Upper Cabinets",
                        "Lower Cabinets"
                    ]
                }
            }
        },
        homemaking: {
            title: "Homemaking Tasks",
            fields: {
                grid: {
                    type: "grid",
                    name: "Homemaking Tasks",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "Dusting",
                        "Vacuuming",
                        "Washing Floors",
                        "Cleaning Bathtub",
                        "Washing / Drying Clothes",
                        "Ironing"
                    ]
                }
            }
        },
        outdoor: {
            title: "Outdoor Tasks",
            fields: {
                grid: {
                    type: "grid",
                    name: "Outdoor Tasks",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "Lawn Care",
                        "Snow Removal"
                    ]
                }
            }
        },
        general: {
            title: "General Accessibility",
            fields: {
                grid: {
                    type: "grid",
                    name: "General Accessibility",
                    canEditHeader: false,
                    columns: [
                        {
                            label: "<h5><b>N/A</b></h5>",
                            image: "Header1.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Ind.</b></h5>",
                            image: "Header2.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Ind.</b></h5>",
                            image: "Header3.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Supervised / Con. Gaurd</b></h5>",
                            image: "Header4.png",
                            imageWidth: "10px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Min. Assist</b></h5><h5><25%</h5>",
                            image: "Header5.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Mod. Assist</b></h5><h5>25-49%</h5>",
                            image: "Header6.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Max. Assist</b></h5><h5>50-74%</h5>",
                            image: "Header7.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Dependent</b></h5><h5>75-100%</h5>",
                            image: "Header8.png",
                            imageWidth: "30px",
                            type: "checkbox",
                            width: 100,
                            value: false
                        },
                        {
                            label: "<h5><b>Comments</b></h5>",
                            type: "textarea",
                            width: 200,
                            value: ""
                        }
                    ],
                    rows: [
                        "Telephone",
                        "Lights",
                        "Main Water Shut Off",
                        "Fuse Panel",
                        "Thermostat",
                        "Dresser",
                        "Closet"
                    ]
                }
            }
        }
    },
    otInformation: {
        address: {
            title: "Evaluation Location",
            fields: {
                address: {
                    name: "Address",
                    type: "textbox",
                    value: null
                },
                city: {
                    name: "City",
                    type: "textbox",
                    value: null
                },
                state: {
                    name: "State",
                    type: "textbox",
                    value: null
                },
                zip_code: {
                    name: "Zip Code",
                    type: "textbox",
                    value: null
                }
            }
        },
        prescription: {
            title: "Rx for OT Services",
            fields: {
                rx: {
                    name: "Rx",
                    type: "text",
                    value: null
                },
                provided_by: {
                    name: "Rx Provided By",
                    type: "textbox",
                    value: null
                },
                date_prescribed: {
                    name: "Date Rx Prescribed",
                    type: "date",
                    value: null
                }
            }
        },
        therapist_notes: {
            title: "Therapist Notes",
            fields: {
                introduction: {
                    name: "Introduction",
                    type: "text",
                    value: null
                },
                description_of_home: {
                    name: "Description of Home",
                    type: "text",
                    value: null
                }
            }
        },
        room_notes: {
            title: "Room Notes",
            fields: {
                parking: {
                    name: "Parking / Garage",
                    type: "text",
                    value: null
                },
                entrances: {
                    name: "Entrances",
                    type: "text",
                    value: null
                },
                bedrooms: {
                    name: "Bedroom(s)",
                    type: "text",
                    value: null
                },
                bathrooms: {
                    name: "Bathroom(s)",
                    type: "text",
                    value: null
                },
                family_room: {
                    name: "Family Room",
                    type: "text",
                    value: null
                },
                living_room: {
                    name: "Living Room",
                    type: "text",
                    value: null
                },
                kitchen: {
                    name: "Kitchen",
                    type: "text",
                    value: null
                },
                dining_room: {
                    name: "Dining Room",
                    type: "text",
                    value: null
                },
                laundry_room: {
                    name: "Laundry Room / Area",
                    type: "text",
                    value: null
                },
                home_office: {
                    name: "Home Office(s)",
                    type: "text",
                    value: null
                },
                interior_stairs: {
                    name: "Interior Stairs",
                    type: "text",
                    value: null
                },
                back_yard: {
                    name: "Back Yard",
                    type: "text",
                    value: null
                }
            }
        },
        recommendations: {
            title: "Home Modification Recommendation",
            fields: {
                summary: {
                    name: "Summary of Home Modifications / Equipment",
                    type: "text",
                    value: null
                },
                plan_of_action: {
                    name: "Recommended Plan of Action",
                    type: "text",
                    value: "<p>Recommended Plan of Action Following this Report:</p>\n" +
                        "<p><br></p>\n" +
                        "<ol>\n" +
                        "    <li>OT provides report to <span class=\"name-input\" data-error=\"Please enter a name for plan of action step 1.\">[ ENTER NAME ]</span> to review and to provide to IP tx team for review/comment.</li>\n" +
                        "    <li>Once /tx team reviews, Case Manager provides information to a designer or architect to complete an initial design plan of proposed modifications, based on this report.</li>\n" +
                        "    <li>This initial design plan is presented to <span class=\"name-input\" data-error=\"Please add a name for plan of action step 3.\">[ ENTER NAME ]</span>, the home evaluation OT, Case Manager and all other interested parties by the designer/architect. (Generally this meeting occurs at client's home). Any changes to plan are discussed at this meeting and architect/designer revises until plan is approved by all parties.</li>\n" +
                        "    <li>Upon approval of design plan, the architect/designer provides full construction documents. These documents are then used&nbsp;to obtain quality competitive bids, submit plan to local building department, and for use during construction.</li>\n" +
                        "    <li>Once construction is completed, home eval. OT evaluates home to ensure all recommended features were included before insurance company provides final payment to contractor.</li>\n" +
                        "</ol>\n" +
                        "<p><br></p>\n" +
                        "<p>If you have any questions on the above information, please contact me at 248-840-3501 during normal business hours.</p>\n" +
                        "<p><br></p>\n" +
                        "<p><br></p>\n" +
                        "<p><img src=\"/assets/static-image/ot_signature.png\" style=\"max-width: 33%\"></p>\n"
                }
            }
        },
        evaluations: {
            title: "Evaluations",
            fields: {
                durable_medical_equipment: {
                    name: "Durable Medical Equipment Evaluation",
                    type: "text",
                    value: null
                }
            }
        }
    }
});

