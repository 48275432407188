app.controller('UserGroupsController', ['$scope', '$http', '$modal', 'DTOptionsBuilder', function ($scope, $http, $modal, DTOptionsBuilder) {

    $scope.dtOptions = DTOptionsBuilder.newOptions()
        .withOption('iDisplayLength', 12)
        .withOption('order', [0, 'asc'])
        .withOption('oLanguage', {'sEmptyTable': 'No groups found'})
        .withBootstrap();

    $scope.userGroups = [];

    $scope.createNewUserGroup = function () {
        var modalInstance = $modal.open({
            templateUrl: 'templates/configuration/create-new-user-group.html',
            controller: 'CreateNewUserGroupController'
        });

        modalInstance.result.then(function (userGroup) {
            console.log(userGroup);
            $scope.userGroups.push(userGroup);
        });
    }

    $http.get('/user-group/all').success(function (response) {
        $scope.userGroups = response;
    });

}]);
