app.controller('DailyActivityReportController', [
    '$scope', '$http', 'SweetAlert', '$stateParams',
    function ($scope, $http, SweetAlert, $stateParams) {
        $scope.params = {
            start_date: new Date(),
            end_date: new Date(),
            user_id: "",
            case_id: "",
            case_type: ""
        };

        $scope.ready   = false;
        $scope.loading = false;

        $scope.totals = {
            billable_time: 0,
            travel_time: 0,
            mileage: 0,
            documentation_time: 0
        };

        $scope.pdfIt = function () {
            var rp        = angular.merge({}, $scope.params);
            rp.start_date = moment(rp.start_date).format("YYYY-MM-DD");
            rp.end_date   = moment(rp.end_date).format("YYYY-MM-DD");
            var p         = $.param(rp);
            window.open("/report/daily-activity-pdf?" + p);
        };

        $scope.csvIt = function () {
            var rp        = angular.merge({}, $scope.params);
            rp.start_date = moment(rp.start_date).format("YYYY-MM-DD");
            rp.end_date   = moment(rp.end_date).format("YYYY-MM-DD");
            var p         = $.param(rp);
            window.open("/report/daily-activity-csv?" + p);
        };

        $http
            .get('/case/all')
            .success(function (result) {
                $scope.allCases = result;
            });

        $http
            .get('/user/all')
            .success(function (result) {
                $scope.users = result;
            });

        $scope.searchCases   = searchCases;
        $scope.searchUsers   = searchUsers;
        $scope.selectedCase  = selectedCase;
        $scope.selectedUser  = selectedUser;
        $scope.getEncounters = getEncounters;

        $scope.$watch('params.case_name', function () {
            if ($scope.params.case_name === '') {
                $scope.params.case_id = '';
            }
        });

        $scope.$watch('params.user_name', function () {
            if ($scope.params.user_name === '') {
                $scope.params.user_id = '';
            }
        });

        function selectedCase(value) {
            $scope.params.case_id = value.id;
            $scope.selected_case  = value;
        }

        function selectedUser(value) {
            $scope.params.user_id = value.id;
            $scope.suser  = value;
        }

        function searchCases(value) {
            let cases = $scope.allCases.filter(function (claim) {
                if (claim.first_name != null && claim.last_name != null) {
                    return claim.first_name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || claim.last_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                }
            });

            return cases;
        }

        function searchUsers(value) {
            return $scope.users.filter(function (user) {
                return user.first_name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || user.last_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
            });
        }

        function getEncounters() {
            $scope.loading = true;
            $scope.totals  = {
                billable_time: 0,
                travel_time: 0,
                mileage: 0,
                documentation_time: 0
            };

            $http
                .post('report/daily-activity', $scope.params)
                .success(function (encounters) {
                    $scope.loading    = false;
                    $scope.ready      = true;
                    $scope.encounters = encounters;

                    $scope.encounters.forEach(function (encounter) {
                        encounter.claim.case_information.basic_information = JSON.parse(encounter.claim.case_information.basic_information);

                        $scope.totals.billable_time += encounter.billable_time;
                        $scope.totals.travel_time += encounter.travel_time;
                        $scope.totals.mileage += encounter.mileage;
                        $scope.totals.documentation_time += encounter.documentation_time;
                    });

                    $scope.totals.billable_time      = Math.round($scope.totals.billable_time * 100) / 100;
                    $scope.totals.travel_time        = Math.round($scope.totals.travel_time * 100) / 100;
                    $scope.totals.documentation_time = Math.round($scope.totals.documentation_time * 100) / 100;
                    $scope.totals.mileage            = Math.round($scope.totals.mileage * 100) / 100;
                }).error(genericErrorHandler);
        }

        function genericErrorHandler(error, code) {
            if (code == 422) {
                for (var property in error) {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }
    }]);
