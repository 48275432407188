app.controller('LoginController', ['$scope', '$http', '$cookieStore', function ($scope, $http, $cookieStore) {

    $scope.login = function() {
        $http.post('/auth/login', { email:$scope.email, password:$scope.password }).success(function(response) {
            if (response.success) {
                $cookieStore.put('token', response.token);
                window.location = '/';
            } else {
                $scope.error = 'Invalid email address and/or password. Please try again.'
            }
        });
    }

}]);
