app.controller('ReviewStartAllController', [
    '$scope', '$http', 'SweetAlert', '$location',
    function ($scope, $http, SweetAlert, $location)
    {
        $scope.loading = true;
        $scope.allCases = {};
        $scope.total = 0;
        getData();

        $scope.makeReport = function (claim) {
            window.open("/#/start-review/" + claim.id);
        };

        function genericErrorHandler(error, code)
        {
            if (code == 422)
            {
                for (var property in error)
                {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else
            {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }

        function getData()
        {
            $http
                .get('/case/all-for-reviews?case_type=Case Manager')
                .success(function (result)
                {
                    $scope.loading = false;
                    $scope.allCases = result.rows;
                    $scope.totalHours = result.totalHours;
                    $scope.totalBilling = result.totalBilling;
                    $scope.totalInprogress = result.totalInprogress;
                })
        }
    }]);
