app.controller('AddOtHomeModificationReportController', [
    '$scope', '$http', '$uibModalInstance', 'options', "SweetAlert", "SummerNoteHelper",
    function ($scope, $http, $uibModalInstance, options, SweetAlert, SummerNoteHelper) {
        SummerNoteHelper.initialize($scope, {
            height: 375
        });

        $scope.caseId = options.caseId;

        if (typeof options.report === "undefined") {
            $scope.instructions = "";
            $scope.editing = false;
        } else {
            $scope.instructions = options.report.instructions;
            $scope.reportId = options.report.id;
            $scope.editing = true;
        }

        $scope.testing = false;


        let getInstructionTemplate = function() {
            $http.get('/report/ot-home-modification-instruction-template')
                .success(function(template) {
                    $scope.instructions = template;
                })
        }

        if (!$scope.editing) {
            getInstructionTemplate();
        }

        $scope.closeModal = function(createdReport = false) {
            $uibModalInstance.close(createdReport);
        }

        $scope.addReport = function() {
            // Make sure that the name-input fields have been modified
            let fields = document.getElementsByClassName("name-input");
            let errors = [];
            for (let i = 0; i < fields.length; i++) {
                if (fields[i].innerHTML == "[ ENTER NAME ]") {
                    errors.push(fields[i].dataset.error);
                }
            }

            if (errors.length > 0 && !$scope.testing) {
                SweetAlert.swal("Error", errors.join("\n"), "error");
            } else {
                let payload = {
                    case_id: $scope.caseId,
                    instructions: $scope.instructions
                }

                $http.post("/report/ot-home-modification", payload)
                    .then((r) => {
                        SweetAlert.swal("Success", "Report was created successfully.", "success");
                        $scope.closeModal(true);
                    })
                    .catch((e) => {
                        SweetAlert.swal("Error", "Report failed to generate\n\nError Code:" + e.status + "\nError: " + e.statusText, "error");
                    })
            }
        }

        $scope.updateReport = function() {
            let payload = {
                id: $scope.reportId,
                case_id: $scope.caseId,
                instructions: $scope.instructions
            };

            $http.patch("/report/ot-home-modification", payload)
                .then((r) => {
                    SweetAlert.swal("Success", "Report was updated successfully.", "success");
                    $scope.closeModal(true);
                })
                .catch((e) => {
                    SweetAlert.swal("Error", "Report failed to update\n\nError Code:" + e.status + "\nError: " + e.statusText, "error");
                })
        }
    }]
);