app.controller('AddressBookSearchController', [
    '$location', '$scope', '$http', '$uibModalInstance', 'DTOptionsBuilder', 'DTColumnBuilder',
    function ($location, $scope, $http, $uibModalInstance, DTOptionsBuilder, DTColumnBuilder) {
        $scope.searches = {};

        $scope.dtInstance = {};

        $scope.$watch('searches', function () {
            $scope.dtInstance.reloadData();
        }, true);

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: 'address/table',
                type: 'POST',
                dataType: "json",
                data: function (data) {
                    console.log(data);
                    for (var i in $scope.searches) {
                        if ($scope.searches[i]) {
                            var col = data.columns.filter(function(column) { return i === column.data})[0];
                            if (col) col.search.value = $scope.searches[i];
                        }
                    }
                }
            })
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('rowCallback', function (row, address) {
                var select = $('<button class="btn btn-primary">Select</button>');

                select.click(function () {
                    $scope.select(address);
                });

                var td = $('<td></td>').append(select);

                $(row).append(td);
            })
            .withPaginationType('full_numbers');

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('company').withTitle('Company'),
            DTColumnBuilder.newColumn('first_name').withTitle('First Name'),
            DTColumnBuilder.newColumn('last_name').withTitle('Last Name'),
            DTColumnBuilder.newColumn('address_1').withTitle('Address 1'),
            DTColumnBuilder.newColumn('city').withTitle('City'),
            DTColumnBuilder.newColumn('state').withTitle('State'),
            DTColumnBuilder.newColumn('zip').withTitle('Zip'),
            DTColumnBuilder.newColumn('category').withTitle('Category'),
            DTColumnBuilder.newColumn('address_2').notVisible(),
            DTColumnBuilder.newColumn('business_phone').notVisible(),
            DTColumnBuilder.newColumn('business_phone_2').notVisible(),
            DTColumnBuilder.newColumn('cell_phone').notVisible(),
            DTColumnBuilder.newColumn('country').notVisible(),
            DTColumnBuilder.newColumn('email').notVisible(),
            DTColumnBuilder.newColumn('fax').notVisible(),
            DTColumnBuilder.newColumn('fax_2').notVisible(),
            DTColumnBuilder.newColumn('id').notVisible(),
            DTColumnBuilder.newColumn('job_title').notVisible(),
            DTColumnBuilder.newColumn('middle_name').notVisible(),
            DTColumnBuilder.newColumn('title').notVisible()
        ];

        $scope.select = function (address) {
            $uibModalInstance.close({address: address});
        };
    }]);
