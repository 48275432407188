app.factory('AutocompleteData', [
    '$http',
    function ($http)
    {
        var availableUsers = [];
        var availableAddresses = [];

        function getData() {
            availableAddresses.length = 0;
            availableUsers.length = 0;

            $http
                .get('/user/all')
                .success(function (users)
                {
                    for (var i in users) availableUsers.push(users[i]);
                });

            $http
                .get('address/all')
                .success(function (addresses)
                {
                    var i, address;

                    for (i = 0; i < addresses.length; i++)
                    {
                        address = addresses[i];
                        var displayValue = "";

                        if (address.last_name) displayValue += address.last_name;
                        if (address.last_name && address.first_name) displayValue += ", ";
                        if (address.first_name) displayValue += address.first_name;
                        if ((address.first_name || address.last_name) && address.company) displayValue += " (";
                        if (address.company) displayValue += address.company;
                        if ((address.first_name || address.last_name) && address.company) displayValue += ")";
                        if (address.city || address.state) displayValue += " -";
                        if (address.city) displayValue += " " + address.city;
                        if (address.state) displayValue += " " + address.state;

                        availableAddresses.push({
                            display: displayValue,
                            id: address.id
                        });
                    }
                });
        }


        getData();

        return {
            users: availableUsers,
            addresses: availableAddresses,
            getData: getData
        }
    }
]);
