app.controller('ReviewAssignmentGroupsController', [
    '$scope', '$http', 'SweetAlert',
    function ($scope, $http, SweetAlert)
    {
        $scope.groups = [];
        $scope.users = [];
        $scope.newGroup = {
            name: '',
            is_default: false
        };
        $scope.selectedGroup = false;
        $scope.selectedGroupId = -1;

        getData();
        $scope.createGroup = createGroup;
        $scope.selectGroup = selectGroup;
        $scope.addUserToGroup = addUserToGroup;
        $scope.removeUserFromGroup = removeUserFromGroup;
        $scope.removeGroup = removeGroup;

        function removeGroup(group)
        {
            $http
                .post('/review/remove-group', {
                    group_id: group
                })
                .success(function () {
                    SweetAlert.swal('Group Removed', 'The group was removed', 'success');
                    getData();
                })
                .error(genericErrorHandler);
        }

        function removeUserFromGroup(user, group)
        {
            $http
                .post('/review/remove-user-from-group', {
                    user_id: user,
                    group_id: group
                })
                .success(function () {
                    SweetAlert.swal('User Removed', 'The user was removed from the group.', 'success');
                    SweetAlert
                    getData();
                })
                .error(genericErrorHandler)
        }

        function addUserToGroup(user, group)
        {
            $http
                .post('/review/add-user-to-group', {
                    user_id: user,
                    group_id: group
                })
                .success(function ()
                {
                    SweetAlert.swal("User Added", 'The user was added to the group!', 'success');
                    getData();
                })
                .error(genericErrorHandler);
        }

        function selectGroup(group_id)
        {
            $scope.selectedGroupId = group_id;
            var groupMatch = $scope.groups.filter(function (group) {
                return group.id == group_id;
            });

            if (groupMatch.length) {
                $scope.selectedGroup = groupMatch[0];
            } else {
                $scope.selectedGroupId = -1;
            }
        }

        function createGroup()
        {
            $http
                .post('/review/new-group', $scope.newGroup)
                .success(function ()
                {
                    SweetAlert.swal("Group Added", 'The group was added!', 'success');
                    getData();
                })
                .error(genericErrorHandler);
        }

        function getData()
        {
            $http
                .get('/review/groups')
                .success(function (result)
                {
                    $scope.groups = result;
                    if ($scope.selectedGroupId != -1) {
                        selectGroup($scope.selectedGroupId);
                    }
                });

            $http
                .get('/user/all')
                .success(function (result)
                {
                    $scope.users = result;
                });
        }

        function genericErrorHandler(error, code)
        {
            if (code == 422)
            {
                for (var property in error)
                {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else
            {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }
    }]);
