app.config(function configure($stateProvider, $urlRouterProvider, $breadcrumbProvider) {
    $urlRouterProvider.otherwise('/');
    $stateProvider.state('dashboard', {
        url: '/',
        controller: 'DashboardController',
        templateUrl: '/templates/dashboard/index.html'
    }).state('cases', {
        url: '/case/list',
        controller: 'CasesController',
        templateUrl: '/templates/case/list.html'
    }).state('case-view', {
        url: '/case/view/{id}',
        controller: 'CaseController',
        templateUrl: '/templates/case/view.html'
    }).state('new-case', {
        url: '/case/new',
        controller: 'NewCaseController',
        templateUrl: '/templates/case/new.html'
    }).state('edit-case', {
        url: '/case/edit/{id}',
        controller: 'EditCaseController',
        templateUrl: '/templates/case/edit.html'
    }).state('users', {
        url: '/users',
        controller: 'UsersController',
        templateUrl: '/templates/users/index.html'
    }).state('user-groups', {
        url: '/user-groups',
        controller: 'UserGroupsController',
        templateUrl: '/templates/user-groups/index.html'
    }).state('addresses', {
        url: '/addresses',
        controller: 'AddressesController',
        templateUrl: '/templates/addresses/list.html'
    }).state('address-new', {
        url: '/addresses/new',
        controller: 'NewAddressController',
        templateUrl: '/templates/addresses/new.html'
    }).state('address-edit', {
        url: '/addresses/edit/{id}',
        controller: 'EditAddressController',
        templateUrl: '/templates/addresses/edit.html'
    }).state('review-groups', {
        url: '/review-groups',
        controller: 'ReviewAssignmentGroupsController',
        templateUrl: '/templates/config/review-assignment-groups.html'
    }).state('start-review', {
        url: '/start-review/:caseId',
        controller: "ReviewStartController",
        templateUrl: '/templates/review/start.html'
    }).state('start-review-all', {
        url: '/start-review-all',
        controller: "ReviewStartAllController",
        templateUrl: '/templates/review/start-all.html'
    }).state('edit-review', {
        url: '/edit-review/{id}',
        controller: "ReviewEditController",
        templateUrl: '/templates/review/edit.html'
    }).state('review/open', {
        url: '/review/open/{id}',
        controller: 'ReviewOpenController',
        templateUrl: '/templates/review/open.html'
    }).state('review/all', {
        url: '/review/all',
        controller: 'ReviewListController',
        templateUrl: '/templates/review/list.html'
    }).state('review/cm-invoices', {
        url: '/review/cm-invoices',
        controller: 'ReviewCmInvoicesController',
        templateUrl: '/templates/review/list-cm-invoice.html'
    }).state('cm-invoice', {
        url: '/cm-invoice/{id}',
        controller: "CmInvoiceController",
        templateUrl: '/templates/review/cm-invoice.html'
    }).state('cm-invoice-custom', {
        url: '/cm-invoice-custom',
        controller: "CmInvoiceCustomController",
        templateUrl: '/templates/review/cm-invoice-custom.html'
    }).state('daily-activity-report', {
        url: '/daily-activity-report',
        controller: 'DailyActivityReportController',
        templateUrl: '/templates/report/daily-activity.html'
    }).state('medical-requests', {
        url: '/medical-requests',
        controller: 'MedicalRequestsController',
        templateUrl: '/templates/report/medical-requests.html'
    }).state('qb-invoice', {
        url: '/qb-invoice/{type}',
        controller: 'QbInvoiceController',
        templateUrl: '/templates/review/qb-invoice.html'
    }).state('audit-logs', {
        url: '/audit-logs',
        controller: 'AuditLogsController',
        templateUrl: '/templates/auditLogs/list.html',
    })
});
