app.controller('PersonalController', [
    '$scope', '$http', '$cookieStore', '$rootScope',
    function ($scope, $http, $cookieStore, $rootScope) {

    $scope.logout = function() {
        $http.post('/auth/logout').success(function(response) {
            if (response.success) {
                $cookieStore.remove('token');
                window.location = '/login';
            }
        });
    };

    $http.get('/user/personal').success(function(response) {
        $scope.name = response.name;
        $scope.admin = response.admin;
        $scope.can_audit = response.can_audit;
        $scope.my_id = response.my_id;
        $rootScope.name = response.name;
        $rootScope.admin = response.admin;
        $rootScope.my_id = response.my_id;
        $rootScope.can_audit = response.can_audit;
    });

}]);
