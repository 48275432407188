app.controller('ReviewOpenController', [
    '$sce', '$scope', '$http', '$cookieStore', '$stateParams', '$uibModal', 'FileUploader', '$location', 'Title', '$timeout', 'SweetAlert', 'caseParser', 'SummerNoteHelper',
    function ($sce, $scope, $http, $cookieStore, $stateParams, $uibModal, FileUploader, $location, Title, $timeout, SweetAlert, caseParser, SummerNoteHelper)
    {

        SummerNoteHelper.initialize($scope);

        $scope.encounterPage = 1;
        $scope.encounterTotalItems = 0;
        $scope.encounterPerPage = 10;
        $scope.hasCaseType = function(type) {
            return typeof $scope.caseInformation !== 'undefined' ? $scope.caseInformation.basic_information.fields.case_types.value.indexOf(type) !== -1 : false;
        }
        var starting_path = $location.path();
        $scope.assign_review = {
            review_id: null,
            user_id: "",
            group_id: ""
        };
        $scope.page_type = "review";

        $http
            .get('/user/all')
            .success(function (result)
            {
                $scope.users = result;
            });

        $http
            .get('/review/groups')
            .success(function (result)
            {
                $scope.groups = result;
            });

        window.onbeforeunload = function ()
        {
            if ($scope.areChanges())
            {
                return "There are unsaved changes. Are you sure you want to leave?";
            }
        };

        $scope.$on('$locationChangeStart', function (event)
        {
            if ($scope.areChanges() && $location.path() !== starting_path)
            {
                var answer = confirm("There are unsaved changes. Are you sure you want to leave?");
                if (!answer)
                {
                    event.preventDefault();
                } else
                {
                    window.onbeforeunload = null;
                }
            }
        });

        $scope.encounterFilter = {};
        $scope.reviewType = "Update";

        $scope.finishedSave = false;

        $scope.$watch('finishedSave', function ()
        {
            if ($scope.finishedSave)
            {
                $timeout(function ()
                {
                    $scope.finishedSave = false;
                }, 5000);
            }
        });

        $scope.filterEncounters = function ()
        {
            $scope.filteredEncounters = [];

            i = 0;
            var t = $scope.encounters.length;
            while (i < t) {
                $scope.filteredEncounters.push($scope.encounters.slice(i, i + $scope.encounterPerPage));
                i += $scope.encounterPerPage;
            }
            $scope.encounterPage = 1;
            $scope.encounterTotalItems = t;
        };

        $http.get('/user/personal').success(function (response)
        {
            $scope.name = response.name;
            $scope.admin = response.admin;
        });

        $scope.assignReview = function ()
        {
            if ($scope.assign_review.user_id == "" && $scope.assign_review.group_id == "")
            {
                SweetAlert.swal('Error', 'Missing the user or group to assign the review', 'error');
                return
            }

            $http
                .post('/review/assign', $scope.assign_review)
                .success(function () {
                    SweetAlert.swal('Assigned', 'The review was successfully assigned!', 'success');
                    $location.path('/review/all');
                }).error(genericErrorHandler);
        };

        $scope.assignReviewComplete = function ()
        {
            $http
                .post('/review/assign-complete', {review_id:  $stateParams.id})
                .success(function () {
                    SweetAlert.swal('Assigned', 'The review was successfully completed', 'success');
                }).error(genericErrorHandler);
        };

        $scope.finishAndGenerate = function ()
        {
            $http
                .post('/review/finish', { review_id: $scope.review.id })
                .success(function ()
                {
                    SweetAlert.swal('Generated', 'The Review has been generated and closed.', 'success');
                    window.open('/review/finished/' + $scope.review.id);
                    $location.path('/review/all');
                }).error(genericErrorHandler);
        };

        $scope.toggleAll = function (page)
        {
            var hide = undefined;
            for (var i in page)
            {
                if (hide == undefined)
                {
                    hide = !page[i].hidden;
                }
                page[i].hidden = hide;
            }
        };

        $scope.areChanges = function ()
        {
            var pages = ['caseInformation', 'clientProfile', 'clientGoals', 'preFunctionalSections', 'postFunctionalSections'];
            for (var j in pages)
            {
                var page = pages[j];
                for (var i in $scope[page])
                {
                    if ($scope[page][i].editing)
                    {
                        return true;
                    }
                }
            }
            return false;
        };

        $scope.isArray = function (section)
        {
            return Array.isArray(section.fields);
        };

        $scope.saveAll = function (page)
        {
            if (page != 'all')
            {
                for (var i in $scope[page])
                {
                    if ($scope[page][i].editing)
                    {
                        var savePage = page;
                        if (page == 'preFunctionalSections' || page == 'postFunctionalSections')
                        {
                            savePage = 'functionalAssessments';
                        }
                        $scope.save(savePage, $scope[page][i]);
                    }
                }
            } else
            {
                var pages = ['caseInformation', 'clientProfile', 'clientGoals', 'preFunctionalSections', 'postFunctionalSections'];
                for (var j in pages)
                {
                    var page = pages[j];
                    var didSaveSomething = false;
                    for (var i in $scope[page])
                    {
                        if ($scope[page][i].editing)
                        {
                            var savePage = page;
                            if (page == 'preFunctionalSections' || page == 'postFunctionalSections')
                            {
                                savePage = 'functionalAssessments';
                            }
                            didSaveSomething = true;
                            $scope.save(savePage, $scope[page][i]);
                        }
                    }
                    if (!didSaveSomething) {
                        SweetAlert.swal('No Action', 'There were no sections to save. Please make a change!', 'warning');
                    }
                }
            }
        };

        $scope.cancelAll = function (page)
        {
            if (page != 'all')
            {
                for (var i in $scope[page])
                {
                    if ($scope[page][i].editing)
                    {
                        for (var j in $scope[page][i])
                        {
                            if (page == 'preFunctionalSections' || page == 'postFunctionalSections')
                                $scope.cancelEditFunctional($scope[page][i]);
                            else
                                $scope.cancelEdit($scope[page][i]);
                        }
                    }
                }
            } else {
                var pages = ['caseInformation', 'clientProfile', 'clientGoals', 'preFunctionalSections', 'postFunctionalSections'];
                for (var j in pages)
                {
                    var page = pages[j];
                    for (var i in $scope[page])
                    {
                        if ($scope[page][i].editing)
                        {
                            for (var j in $scope[page][i])
                            {
                                if (page == 'preFunctionalSections' || page == 'postFunctionalSections')
                                    $scope.cancelEditFunctional($scope[page][i]);
                                else
                                    $scope.cancelEdit($scope[page][i]);
                            }
                        }
                    }
                }
            }
        };

        $scope.cancelEditFunctional = function(section)
        {
            if (section.previous_status) {
                section.status = angular.copy(section.previous_status);
                delete section.previous_status;
            }
            if (section.previous_comments) {
                section.comments = angular.copy(section.previous_comments);
                delete section.comments;
            }
            section.editing = false;
        };

        $scope.cancelEdit = function (section)
        {
            if (section.previous_fields)
            {
                section.fields = angular.copy(section.previous_fields);
                delete section.previous_fields;
            } else {
                SweetAlert.swal('Error', "There was an error restoring the previous information for " + section, 'error');
            }
            section.editing = false;
        };

        $scope.editingFunctional = function (section)
        {
            section.previous_status = angular.copy(section.status);
            section.previous_comments = angular.copy(section.comments);
            section.editing = true;
        };

        $scope.editing = function (section)
        {
            section.previous_fields = angular.copy(section.fields);

            section.editing = true;
        };

        $scope.hasCaseType = function(type) {
            return typeof $scope.caseInformation !== 'undefined' ? $scope.caseInformation.basic_information.fields.case_types.value.indexOf(type) !== -1 : false;
        }

        $scope.save = function (type, section) {
            section.saving = true;

            var data        = angular.copy($scope[type]);
            if (type === 'functionalAssessments') {
                data = {
                    preFunctionalSections: angular.copy($scope.preFunctionalSections),
                    postFunctionalSections: angular.copy($scope.postFunctionalSections)
                };

                for (var key in data.preFunctionalSections) {
                    if (data.preFunctionalSections.hasOwnProperty(key)) {
                        if (!data.preFunctionalSections[key].editing) {
                            delete data.preFunctionalSections[key];
                        }
                    }
                }
                for (var key in data.postFunctionalSections) {
                    if (data.postFunctionalSections.hasOwnProperty(key)) {
                        if (!data.postFunctionalSections[key].editing) {
                            delete data.postFunctionalSections[key];
                        }
                    }
                }

            } else {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (!data[key].editing) {
                            delete data[key];
                        }
                    }
                }
            }

            $http.post('/case/save', {
                claim: $scope.case,
                section: type,
                section_data: data
            }).then(function (r) {
                section.saving = false;
                if (!r.data.success) {
                    alert("There was an unexpected error saving your work. Please contact Patti and report this error.");
                    return;
                }

                if (section && section.hasOwnProperty('previous_fields')) {
                    delete section.previous_fields;
                    section.editing = false;
                }
            }, function () {
                section.saving = false;
                alert("Unable to contact the server to save. Please try to save again.");
            });
        };

        $scope.trustAsHtml = function (string)
        {
            return $sce.trustAsHtml(string);
        };

        $scope.toggleSection = function (section)
        {
            if (!section.hidden)
            {
                section.hidden = true;
            } else
            {
                section.hidden = false;
            }
        };

        $scope.gotoEncounterTop = function () {
            jQuery("body").animate({scrollTop: jQuery("#encountertop").offset().top}, "slow");
        };

        $http.get('/review/open?id=' + $stateParams.id).success(function (result)
        {
            $scope.case = result.case;
            $scope.review = result.review;
            $scope.encounters = result.encounters;
            $scope.filteredEncounters = [];
            $scope.filterEncounters();

            $scope.assign_review.review_id = result.review.id;

            var c = result.case;

            var data = caseParser(c);

            $scope.caseInformation = data.caseInformation;
            $scope.clientProfile = data.clientProfile;
            $scope.clientGoals = data.clientGoals;
            $scope.preFunctionalSections = data.preFunctionalSections;
            $scope.postFunctionalSections = data.postFunctionalSections;


            var name = $scope.caseInformation.basic_information.fields.last_name.value + ', ' + $scope.caseInformation.basic_information.fields.first_name.value;
            Title.setTitle($scope.review.update_number + " Review - " + name);

            for (var i in $scope.clientProfile)
            {
                $scope.clientProfile[i].hidden = true;
                $scope.clientProfile[i].editing = false;
            }

            for (var i in $scope.clientGoals)
            {
                $scope.clientGoals[i].hidden = true;
                $scope.clientGoals[i].editing = false;
            }

            for (var i in $scope.preFunctionalSections)
            {
                $scope.preFunctionalSections[i].hidden = true;
                $scope.preFunctionalSections[i].editing = false;
            }
            for (var i in $scope.postFunctionalSections)
            {
                $scope.postFunctionalSections[i].hidden = true;
                $scope.postFunctionalSections[i].editing = false;
            }

            for (var i in $scope.caseInformation)
            {
                $scope.caseInformation[i].hidden = true;
                $scope.caseInformation[i].editing = false;
            }

        });

        $scope.editEncounter = function (encounter)
        {
            var currentEncounter = angular.merge({}, encounter);
            var case_info = $scope.caseInformation.basic_information.fields;
            var modal = $uibModal.open({
                templateUrl: '/templates/case/edit-encounter.html',
                controller: 'EditEncounterController',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    encounter: function ()
                    {
                        return currentEncounter;
                    },
                    caseTypes: function() {
                        return case_info.case_types;
                    }
                }
            });
            modal.result.then(function (newEncounter)
            {
                if (newEncounter)
                {
                    angular.merge(encounter, newEncounter);
                }
            });
        };

        $scope.addMedRequestFromEncounter = function (encounter) {

            var modal = $uibModal.open({
                templateUrl: '/templates/case/med-request-popup.html',
                controller: [
                    "$scope", "$uibModalInstance", 
                    function ($innerScope, $uibModalInstance) {

                        $innerScope.provider        = {
                            type: 'addressBook',
                            value: null
                        };
                        $innerScope.details         = {
                            type: 'text',
                            value: null
                        };
                        $innerScope.date_of_service = {
                            type: 'date',
                            value: moment(encounter.start_time).format("MM/DD/YYYY")
                        };
                        $innerScope.date_requested  = {
                            type: 'date',
                            value: moment(encounter.start_time).format("MM/DD/YYYY")
                        };
                        $innerScope.is_stat = {
                            type: "checkbox",
                            value: false
                        };
                        $innerScope.finish = function () {
                            $uibModalInstance.close({
                                provider: $innerScope.provider,
                                details: $innerScope.details,
                                date_of_service: $innerScope.date_of_service,
                                date_requested: $innerScope.date_requested,
                                is_stat: $innerScope.is_stat,
                            });
                        };
                        $innerScope.cancel = function () {
                            $uibModalInstance.dismiss();
                        };
                    }],
                size: 'md',
                backdrop: 'static'
            });
            modal.result.then(function (request) {
                var template = angular.merge({}, $scope.caseInformation.medical_requests.fields_template);
                template.provider.value = request.provider.value;
                template.details.value = request.details.value;
                template.date_of_service.value = request.date_of_service.value;
                template.date_requested.value = request.date_requested.value;
                template.requester.value = $rootScope.name;
                template.is_stat.value = request.is_stat.value;
                $scope.caseInformation.medical_requests.fields.push(template);
                $scope.caseInformation.medical_requests.editing = true;
                $scope.save('caseInformation', $scope.caseInformation.medical_requests);
            });
        };

        $scope.addRow = function (section)
        {
            $scope.addRow = function (section)
            {
                section.fields.push(angular.merge({}, section.fields_template));
            };
        };

        $scope.encounters = [];


        function genericErrorHandler(error, code)
        {
            if (code == 422)
            {
                for (var property in error)
                {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else
            {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }

    }]);
