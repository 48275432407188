app.controller('NewCaseController', ['$location', '$scope', '$http', '$cookieStore', '$stateParams', 'caseFields', function ($location, $scope, $http, $cookieStore, $stateParams, caseFields) {



    $scope.caseTypes = caseFields.caseInformation.basic_information.fields.case_types;
    console.log($scope.caseTypes);
    $scope.case = {}
    $scope.error = false;
    $scope.loading = false;

    $scope.create = function(r) {

        $scope.error = false;
        $scope.loading = true;

        $scope.case['case_types'] = $scope.caseTypes.value;

        $http.post('/case/create', $scope.case).success(function(r) {
            $scope.loading = false;
            if (r.success) {
                $location.path('/case/view/' + r.id);
            } else {
                $scope.error = r.error;
            }
            console.log(r);
        });
    }

    $http.get('/user/all').success(function(users) {
        $scope.users = users;
    });

    $scope.toggleCaseType = function(type) {
        let index = $scope.caseTypes.value.indexOf(type);

        if (index === -1) {
            $scope.caseTypes.value.push(type);
        } else {
            $scope.caseTypes.value.splice(index, 1);
        }
    }


}]);