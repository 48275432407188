app.controller('AddressesController', [
    '$location', '$scope', '$http', '$cookieStore', '$stateParams','Title', 'DTOptionsBuilder', 'DTColumnBuilder', '$uibModal',
    function ($location, $scope, $http, $cookieStore, $stateParams, Title, DTOptionsBuilder, DTColumnBuilder, $uibModal)
    {
        $scope.dtInstance = {};
        $scope.preferred_only = 0;
        $scope.searches = {};

        $scope.$watch('preferred_only', function () {
            $scope.dtInstance.reloadData();
        });
        $scope.$watch('searches', function () {
            $scope.dtInstance.reloadData();
        }, true);

        Title.setTitle('All Addresses');

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: 'address/table',
                type: 'POST',
                dataType: "json",
                data: function (data) {
                    if ($scope.preferred_only) {
                        data.columns[23].search.value = 1;
                    }
                    for (var i in $scope.searches) {
                        if ($scope.searches[i]) {
                            var col = data.columns.filter(function(column) { return i === column.data})[0];
                            if (col) col.search.value = $scope.searches[i];
                        }
                    }
                }
            })
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('rowCallback', function (row, address)
            {
                var editButton = $('<button class="btn btn-warning">Edit</button>');
                var deleteButton = $('<button class="btn btn-danger">Delete</button>');

                editButton.click(function () { $scope.edit(address); });
                deleteButton.click(function () { $scope.delete(address); });

                var btnGroup = $('<div class="btn-group btn-group-xs"></div>').append(editButton, deleteButton);

                var td = $('<td style="width: 100px;"></td>').append(btnGroup);

                $(row).append(td);
            })
            .withPaginationType('full_numbers');

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('company').withTitle('Company'),
            DTColumnBuilder.newColumn('first_name').withTitle('First Name'),
            DTColumnBuilder.newColumn('last_name').withTitle('Last Name'),
            DTColumnBuilder.newColumn('address_1').withTitle('Address 1'),
            DTColumnBuilder.newColumn('city').withTitle('City'),
            DTColumnBuilder.newColumn('state').withTitle('State'),
            DTColumnBuilder.newColumn('zip').withTitle('Zip'),
            DTColumnBuilder.newColumn('category').withTitle('Category'),
            DTColumnBuilder.newColumn('address_2').notVisible(),
            DTColumnBuilder.newColumn('advocate_id').notVisible(),
            DTColumnBuilder.newColumn('business_phone').notVisible(),
            DTColumnBuilder.newColumn('business_phone_2').notVisible(),
            DTColumnBuilder.newColumn('cell_phone').notVisible(),
            DTColumnBuilder.newColumn('country').notVisible(),
            DTColumnBuilder.newColumn('created_at').notVisible(),
            DTColumnBuilder.newColumn('email').notVisible(),
            DTColumnBuilder.newColumn('fax').notVisible(),
            DTColumnBuilder.newColumn('fax_2').notVisible(),
            DTColumnBuilder.newColumn('id').notVisible(),
            DTColumnBuilder.newColumn('job_title').notVisible(),
            DTColumnBuilder.newColumn('middle_name').notVisible(),
            DTColumnBuilder.newColumn('title').notVisible(),
            DTColumnBuilder.newColumn('updated_at').notVisible(),
            DTColumnBuilder.newColumn('preferred_provider').notVisible()
        ];

        $scope.delete = function (address)
        {
            if (!confirm('Are you sure you want to delete the address for \'' + address.company + '\'?'))
            {
                return;
            }
            $http.post('/address/delete', {id: address.id}).success(function ()
            {
                $scope.dtInstance.reloadData();
            });
        };

        $scope.edit = function (address)
        {
            var modal = $uibModal.open({
                controller: "EditAddressController",
                templateUrl: '/templates/addresses/edit.html',
                size: 'lg',
                resolve: {
                    addressId: function () { return address.id; }
                }
            });

            modal.result.then(function () {
                $scope.dtInstance.reloadData();
            });
        };

        $scope.add = function () {
            var modal = $uibModal.open({
                templateUrl: '/templates/addresses/new.html',
                controller: 'NewAddressController',
                size: 'lg'
            });

            modal.result.then(function ()
            {
                $scope.dtInstance.reloadData();
            });
        }

        // $http.get('/address/all').success(function (r)
        // {
        //     $scope.addresses = r;
        // })

    }]);
