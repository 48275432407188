app.controller('CasesController', [
    '$location', '$scope', '$http', '$q', '$cookieStore', '$stateParams', 'Title', 'DTOptionsBuilder', 'DTColumnBuilder', 'AutocompleteData', 'BufferFilter', 'Timer',
    function ($location, $scope, $http, $q, $cookieStore, $stateParams, Title, DTOptionsBuilder, DTColumnBuilder, AutocompleteData, BufferFilter, Timer)
    {
        Title.setTitle('List Cases');

        $scope.tableReady = false;
        $scope.dataLoaded = false;

        $scope.dtOptions = new DTOptionsBuilder.newOptions();
        //$scope.dtOptions.deferRender = true;
        $scope.dtOptions.searching = false;
        $scope.dtOptions.paging = false;
        $scope.dtOptions.destroy = true;

        $scope.usersList = AutocompleteData.users;

        $scope.filters = {
            first_name: "",
            last_name: "",
            primary_case_manager: "",
            case_status: ""
        };

        BufferFilter.filterFn = function() {

            let cases = $scope.cases;

            if ($scope.filters.first_name !== '') {
                cases = cases.filter(makeFilter('first_name', $scope.filters.first_name));
            }
            if ($scope.filters.last_name !== '') {
                cases = cases.filter(makeFilter('last_name', $scope.filters.last_name));
            }
            if ($scope.filters.primary_case_manager !== '') {
                cases = cases.filter(makeFilter('primary_case_manager', $scope.filters.primary_case_manager));
            }
            if ($scope.filters.case_status !== '') {
                cases = cases.filter(makeFilter('case_status', $scope.filters.case_status));
            }

            $scope.displayingCases = cases;
        }

        $scope.$watch('filters', function () {
            BufferFilter.dispatch();
        }, true);

        $http.get('/case/all').success(function (r)
        {
            $scope.cases = r;
            $scope.displayingCases = r;
            $scope.filters.case_status = "Open";

            $scope.dataLoaded = true;
        });


    }]);


function makeFilter(field, compare) {
    var l = compare.length;
    return function (i) {
        if (i[field] && compare)
            return i[field].toLowerCase().substring(0, l) === compare.toLowerCase();
        else
            return false;
    }
}
