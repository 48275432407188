app.controller('NewAddressController', [
    '$location', '$scope', '$http', '$uibModalInstance', 'AutocompleteData',
    function ($location, $scope, $http, $uibModalInstance, AutocompleteData) {

        $scope.submit = function () {
            $http.post('/address/create', $scope.address).success(function (r) {
                $uibModalInstance.close(r);
                AutocompleteData.getData();
            });
        }

    }]);
