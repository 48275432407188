window.app = angular.module('app', [
    'CompiledTemplates',
    'ui.router',
    'ngSanitize',
    'ngCookies',
    'ncy-angular-breadcrumb',
    'datatables',
    'datatables.bootstrap',
    'datatables.columnfilter',
    'ui.bootstrap',
    'angularFileUpload',
    'bcherny/formatAsCurrency',
    'summernote',
    'oitozero.ngSweetAlert',
    'ui.mask'
]);

app.run(function ($rootScope, $http, $location, $timeout) {
    $rootScope.isActive = function (viewLocation) {
        return viewLocation === $location.path();
    };

    $rootScope.all_users = [];

    $rootScope.$on("user_authed", function () {
        $http.get('/user/all').success(function (response) {
            $rootScope.all_users = response.data.map(function (user) {
                return user.first_name + " " + user.last_name;
            });
        });
        ping();
    });

    $rootScope.online = true;

    $rootScope.createInvoice = function () {
        var start = window.prompt("First Invoice Number?", "0");

        window.open("/qb/all-finished?start=" + start);
    };

    ping();

    function ping() {
        $http.get('/user/ping').success(function (response) {
            $rootScope.online = !!response.success;
            $timeout(ping, 5000);
        }).error(function (e) {
            console.log(e);
            if (e !== "Unauthorized") {
                $rootScope.online = false;
                $timeout(ping, 1000);
            }
        });
    }
});

app.config(['uiMask.ConfigProvider', function(uiMaskConfigProvider) {
    uiMaskConfigProvider.modelViewValue = true;
}]);

app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push(['$q', '$cookieStore', '$location', function ($q, $cookieStore, $location) {
        return {
            request: function (httpConfig) {
                try {
                    httpConfig.headers['token'] = $cookieStore.get('token');
                    return httpConfig;
                }
                catch(error) {
                    $cookieStore.remove('token');
                    // Cookie getter for token failed, this is likely due to an sinvalid format or something missing
                }

            },
            responseError: function (response) {
                if (response.status === 401) {
                    $cookieStore.remove('token');
                    if (window.location.pathname !== "/login") {
                        window.location = '/login';
                    }
                }
                return $q.reject(response);
            }
        }
    }]);
}]);

app.filter('cuiDate', function myDateFormat($filter){
    return function(text){
        if (text instanceof Date) text = text.toString();
        if (text == "" || text == null) return "";
        var tempdate = new Date(text.replace(/-/g,"/"));
        return $filter('date')(tempdate, "M/dd/yyyy");
    }
});

app.filter('cuiDateTime', function myDateFormat($filter){
    return function(text){
        if (text instanceof Date) text = text.toString();
        if (text == "" || text == null) return "";
        var tempdate = new Date(text.replace(/-/g,"/"));
        return $filter('date')(tempdate, "M/dd/yyyy h:mm a");
    }
});

app.service('BufferFilter', function() {
    this.delay = 50;
    this.filterFn = null;
    this.timeout = null;

    this.applyTimeout = function() {
        let self = this;
        this.timeout = setTimeout(function() {
            self.filterFn();
            self.timeout = null;
        }, this.delay);
    }

    this.dispatch = function() {
        if (this.filterFn === null) {
            console.error("ERROR - Filter function must be set before using dispatch");
        } else {
            if (this.timeout === null) {
                this.applyTimeout();
            } else {
                clearTimeout(this.timeout);
                this.applyTimeout();
            }
        }
    }
})

app.service('Timer', function() {
    this.timers = {};

    this.start = function(operation) {
        this.timers[operation] = {
            start: new Date(),
            end: null,
            delta: 0
        };
    }

    this.stop = function(operation) {
        let timer = this.timers[operation];
        timer.end = new Date();
        timer.delta = timer.end - timer.start;
        console.log(operation + " took " + timer.delta + "ms");
    }
})

app.service('ToggleManager', function() {
    this.tabs = {};

    this.addTab = function(tabName, panels) {
        let panel = {};

        panels.forEach(p => {
            panel[p] = false;
        });

        this.tabs[tabName] = panel;
    }

    this.togglePanel = function(tabName, panelName) {
        this.tabs[tabName][panelName] = !this.tabs[tabName][panelName];
    }

    this.openPanel = function(tabName, panelName) {
        this.tabs[tabName][panelName] = true;
    }

    this.closePanel = function(tabName, panelName) {
        this.tabs[tabName][panelName];
    }

    this.openAllPanels = function(tabName) {
        for (let panelName in this.tabs[tabName]) {
            this.tabs[tabName][panelName] = true;
        }
    }

    this.closeAllPanels = function(tabName) {
        for (let panelName in this.tabs[tabName]) {
            this.tabs[tabName][panelName] = false;
        }
    }

    this.isPanelOpen = function(tabName, panelName) {
        return this.tabs[tabName][panelName];
    }
});

document.addEventListener('drop', function(e) {
    e.preventDefault();
})

//The following global bs event handlers fixes the issues SummerNote has with modals, i.e. closing a toolbar modal will cause any other modal to stop scrolling

const BOTTOM_Z_INDEX = 1050;

$(document).on("show.bs.modal", '.modal', function(event) {
    let zIndex = BOTTOM_Z_INDEX + (10 * $(".modal:visible").length);
    console.log($(".modal:visible").length);
    $(this).css("z-index", zIndex);
    console.log(zIndex);
    setTimeout(function () {
        $(".modal-backdrop").not(".modal-stack").first().css("z-index", zIndex - 1).addClass("modal-stack");
    }, 0);
})

$(document).on("hidden.bs.modal", '.modal', function () {
    if ($(".modal:visible").length > 0){
        $("body").addClass("modal-open");
    }
});

$(document).on('inserted.bs.tooltip', function (event) {
    let zIndex = BOTTOM_Z_INDEX + (10 * $(".modal:visible").length);
    let tooltipId = $(event.target).attr("aria-describedby");
    let tooltip = $('#' + tooltipId);
    tooltip.css("z-index", zIndex);
});

$(document).on('inserted.bs.popover', function (event) {
    let zIndex = BOTTOM_Z_INDEX + (10 * $(".modal:visible").length);
    let popoverId = $(event.target).attr("aria-describedby");
    let popover = $("#" + popoverId);
    popover.css("z-index", zIndex);
});

$(".arrow").on('mouseup', function(e) {
    console.log(e);
})

//SummerNote Service to add the necessary scope attribs and the fix for chrome drag and drop not working properly.

const SUMMERNOTE_IMAGE_CLASS = "summernote-image";

const relativizeImages = (content) => {
    let summernoteObject = $(content);
    let parent = $(summernoteObject.get(0)).parent();
    let children = summernoteObject.children();
    children.each(function(index) {
        if ($(this).hasClass("summernote-image")) {
            let parentWidth = $(".note-editing-area").outerWidth(false);
            let imgWidth = $(this).outerWidth(false);

            let ratio = Math.round(100 * (imgWidth / parentWidth));

            $(this).css("width", ratio + "%");
            $(this).css("height", "auto");
            console.log($(this).css("width"));
        }
    })

}

app.service("SummerNoteHelper", function($http, SweetAlert) {
   this.scope = null;
   this.defaultOptions = {
       height: 300,
       callbacks: {
           onChange: function(content) {
               //let parentWidth = $(this).innerWidth();
               //relativizeImages(content);
           },
           onImageLinkInsert: function(link) {
               $http.post("/assets/image-link", {link}).then((response) => {
                   let data = response.data;
                   if (data.success) {
                       let url = "/assets/image/" + data.image;
                       let img = document.createElement("img");
                       img.src = url;
                       img.className = SUMMERNOTE_IMAGE_CLASS;

                       $(this).summernote('insertNode', img);

                   } else {
                        SweetAlert.swal("Image failed to upload", "Failed to load image from URL - Please try downloading the image and uploading directly.", "error");
                   }
               }).catch((e) => {
                   SweetAlert.swal("Image failed to upload", "Server error - please report this to your IT representative", "error");
               })
           },
           onImageUpload: function(images) {
               let imageData = new FormData();
               imageData.set("image", images[0]);

               //let self = this;

               $http.post("/assets/image", imageData, {
                   transformRequest: angular.identity,
                   headers: {
                       'Content-Type': undefined,
                       'Process-Data': false
                   }
               })
                   .then((response) => {
                       let data = response.data;
                       if (data.success) {
                           let url = "/assets/image/" + data.image;
                           let img = document.createElement("img");
                           img.src = url;
                           img.className = SUMMERNOTE_IMAGE_CLASS;

                           $(this).summernote('insertNode', img);
                       } else {
                           SweetAlert.swal("Image failed to upload", "Failed to store image in server because: " + data.error + ".", "error");
                       }
                   })
                   .catch(function(error) {
                       SweetAlert.swal("Image failed to upload", "Server error - please report this to your IT representative", "error");
                   })
           },
           onPaste: function(e, ne) {

               let images = e.originalEvent.clipboardData.files;
               if (images.length > 0) {
                   e.preventDefault();

                   let imageData = new FormData();
                   imageData.set("image", images[0]);

                   //let self = this;

                   $http.post("/assets/image", imageData, {
                       transformRequest: angular.identity,
                       headers: {
                           'Content-Type': undefined,
                           'Process-Data': false
                       }
                   })
                       .then((response) => {
                           let data = response.data;
                           if (data.success) {
                               let url = "/assets/image/" + data.image;
                               let img = document.createElement("img");
                               img.src = url;
                               img.className = SUMMERNOTE_IMAGE_CLASS;

                               $(this).summernote('insertNode', img);

                           } else {
                               SweetAlert.swal("Image failed to upload", "Failed to store image in server because: " + data.error + ".", "error");
                           }
                       })
                       .catch(function (error) {
                           SweetAlert.swal("Image failed to upload", "Server error - please report this to your IT representative", "error");
                       })
               }
           }
       }
   };

   this.processImages = (images, editorPointer) => {
       let imageData = new FormData();
       imageData.set("image", images[0]);
       $http.post("/assets/image", imageData, {
           transformRequest: angular.identity,
           headers: {
               'Content-Type': undefined,
               'Process-Data': false
           }
       })
           .then((response) => {
               let data = response.data;
               if (data.success) {
                   let url = "/assets/image/" + data.image;
                   let img = document.createElement("img");
                   img.src = url;
                   img.className = SUMMERNOTE_IMAGE_CLASS;

                   editorPointer.summernote('insertNode', img);
               } else {
                   SweetAlert.swal("Image failed to upload", "Failed to store image in server because: " + data.error + ".", "error");
               }
           })
           .catch(function(error) {
               SweetAlert.swal("Image failed to upload", "Server error - please report this to your IT representative", "error");
           })
   }


   this.initialize = (scope, options = {}) => {
       this.scope = scope;
       this.scope.editor = {};
       this.scope.editable = {};

       this.internalScope = {};

       //Override the default options with whatever is passed.
       this.scope.options = {
           ...this.defaultOptions,
           ...options
       };


       this.scope.editorInit = (targetId) => {

           targetId = typeof targetId !== 'undefined' ? targetId : this.scope.options.targetId;

           let parentElement = typeof targetId !== 'undefined' ? document.getElementById(targetId) : document;

           console.log(parentElement);

           let elements = parentElement.getElementsByClassName('note-editor');

           console.log(elements);

           this.scope.editor = $(parentElement.getElementsByClassName('summernote')[0]);
           this.scope.editable = $(parentElement.getElementsByClassName('note-editor')[0]);

           for (let i = 0; i < elements.length; i++) {
               elements[i].addEventListener('drop', (e) => {
                   e.preventDefault();

                   let scope = angular.element(e.target).scope();
                   targetId = typeof targetId !== 'undefined' ? targetId : scope.options.targetId;
                   let parentElement = typeof targetId !== 'undefined' ? document.getElementById(targetId) : document;

                   scope.editor = $(parentElement.getElementsByClassName('summernote')[0]);
                   scope.editable = $(parentElement.getElementsByClassName('note-editor')[0]);

                   for (let i = 0; i < e.dataTransfer.files.length; i++) {
                       let file = e.dataTransfer.files[i];
                       let imageData = new FormData();
                       imageData.set("image", file);

                       $http.post("/assets/image", imageData, {
                           transformRequest: angular.identity,
                           headers: {
                               'Content-Type': undefined,
                               'Process-Data': false
                           }
                       })
                       .then((response) => {
                           let data = response.data;
                           if (data.success) {
                               let url = "/assets/image/" + data.image;
                               let img = document.createElement("img");
                               img.src = url;
                               img.className = SUMMERNOTE_IMAGE_CLASS;
                               scope.editor.summernote('insertNode', img);
                           } else {
                               SweetAlert.swal("Image failed to upload", "Failed to store image in server because: " + data.error + ".", "error");
                           }
                       })
                       .catch(function(error) {
                           SweetAlert.swal("Image failed to upload", "Server error - please report this to your IT representative", "error");
                       })
                   }
               }, false);
           }

           let editors = document.getElementsByClassName('note-editor');

           for (let i = 0; i < editors.length; i++) {
               let images = editors[i].getElementsByTagName("IMG");

               for (let j = 0; j < images.length; j++) {
                   images[j].className = SUMMERNOTE_IMAGE_CLASS;
               }
           }
       }
   }

   this.relativizeImages = relativizeImages;
});



moment.tz.setDefault("America/Detroit");
