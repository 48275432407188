app.controller('ReviewStartController', [
    '$scope', '$http', 'SweetAlert', '$location', '$stateParams',
    function ($scope, $http, SweetAlert, $location, $stateParams) {

        $scope.newReview = {
            case_id: '',
            case_type: "Case Manager",
            type: '',
            start: new Date(),
            end: new Date(),
            update_number: "Update # "
        };

        $scope.allCases = {};

        let getOpenCases = new Promise((resolve, reject) => {
            $http
                .get('/case/open')
                .success((result) => {
                    $scope.allCases = result;
                    resolve();
                })
                .error(_ => {
                    console.warn("Warning - failed to fetch cases");
                    reject();
                })
        });

        getOpenCases.then(_ => {
            initializeReview();
            getClaimReviews();
        })


        $scope.searchCases = searchCases;
        $scope.selectedCase = selectedCase;
        $scope.startReview = startReview;

        $scope.$watch('newReview', getSalesBreakdown, true);


        function getSalesBreakdown() {
            if (
                $scope.newReview.case_id != '' &&
                $scope.newReview.type != ''
            ) {
                $http
                    .post('/review/sales-review-breakdown?case_type=Case Manager', $scope.newReview)
                    .success(function (result) {
                        $scope.salesBreakdown = result;
                    });
            }

        }

        function startReview() {
            $http
                .post('review/start', $scope.newReview)
                .success(function (result) {
                    console.log(result);
                    SweetAlert.swal({
                        title: "success",
                        text: "The report was created",
                        type: "success"
                    });

                    $scope.salesBreakdown = {};

                    $scope.newReview = {
                        case_id: '',
                        case_type: "Case Manager",
                        type: '',
                        start: new Date(),
                        end: new Date(),
                        update_number: "Update # "
                    };
                })
                .error(genericErrorHandler)
        }

        function selectedCase(value) {
            $scope.newReview.case_id = value.id;
            $stateParams.caseId = value.id;

            getClaimReviews();
        }

        function searchCases(value) {
            let cases = $scope.allCases.filter(function (claim) {
                if (claim.first_name != null && claim.last_name != null) {
                    return claim.first_name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || claim.last_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                }
            });

            return cases;
        }

        function genericErrorHandler(error, code) {
            if (code == 422) {
                for (var property in error) {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            } else {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }

        function getClaim() {
            let claims = $scope.allCases.filter(c => {
                return c.id == $stateParams.caseId;
            });
            if (claims.length === 0) {
                return null;
            } else {
                return claims[0];
            }
        }

        function initializeReview() {
            let claim = getClaim();
            if (claim !== null) {
                $scope.newReview.claim_name = claim.last_name + ", " + claim.first_name;
                $scope.newReview.case_id = claim.id;
            }
        }

        function getClaimReviews() {
            if (typeof $stateParams.caseId !== 'undefined' && $stateParams.caseId !== "") {

                $http.get('/case/for-review-start?case_type=Case Manager&case=' + $stateParams.caseId)
                    .success(function (d) {
                        console.log(d);
                        $scope.newReview.start = moment(d['start']).toDate();
                        $scope.newReview.end = moment(d['end']).toDate();
                        $scope.newReview.type = "update";

                        let num = parseInt(d['last_update'].replace(/\D/g, ''));

                        if (num) {
                            $scope.newReview.update_number = "Update # " + (num + 1);
                        }
                    })
                    .error(_ => {
                        $scope.newReview.start = moment(new Date()).toDate();
                        $scope.newReview.type = "initial";
                        $scope.newReview.update_number = "Update # 1";
                    });
            } else {
                console.warn("Warning - no case id was found in state parameters");
            }
        }
    }
]);

