app.controller('AddEncounterController', [
    '$scope', '$http', '$uibModalInstance', 'options', "SweetAlert", "SummerNoteHelper",
    function ($scope, $http, $uibModalInstance, options, SweetAlert, SummerNoteHelper) {
        var d            = new Date();

        SummerNoteHelper.initialize($scope);

        d.setMilliseconds(0);

        $scope.isSaving = false;
        $scope.encounter = {
            start_date: d,
            case_id: options.caseId,
            case_type: options.caseTypes.value.length > 0 ? options.caseTypes.value[0] : ""
        };

        $scope.caseTypes = options.caseTypes;

        if (options.type) {
            $scope.encounter.type = options.type;
        }

        if (options.billable_time) {
            $scope.encounter.billable_time = options.billable_time;
        }

        $scope.limitTime = function () {
            $scope.encounter.billable_time      = parseFloat(Math.round($scope.encounter.billable_time * 10) / 10);
            $scope.encounter.travel_time        = parseFloat(Math.round($scope.encounter.travel_time * 10) / 10);
            $scope.encounter.documentation_time = parseFloat(Math.round($scope.encounter.documentation_time * 10) / 10);
            $scope.encounter.mileage            = parseFloat(Math.round($scope.encounter.mileage));
        };

        $scope.ok = function () {
            $scope.isSaving = true;
            if (!$scope.encounter.type) {
                $scope.isSaving = false;
                alert('Please select an encounter type');
                return;
            }

            if ($scope.encounter.mileage >= 600) {
                var conf = confirm("The mileage entered is greater than 600 miles. Is this correct?");

                if (!conf) {
                    $scope.isSaving = false;
                    return;
                }
            }

            $http.post('/case/add-encounter', $scope.encounter).success(function (r) {
                if (r.valid) {
                    SweetAlert.swal("Success", "The encounter was saved to the server.");
                    $uibModalInstance.close(r.encounter);
                } else {
                    SweetAlert.swal("Error", 'An unknown error occurred. Please try again. If the problem persists, please document what circumstances lead to this error and report to the appropriate person', 'error');
                }
                $scope.isSaving = false;
            }).catch(function (e) {
                if (e.status === 400) {
                    SweetAlert.swal("Error", e.data.error, 'error');
                } else {
                    SweetAlert.swal("Error", 'An unknown error occurred. Please try again. If the problem persists, please document what circumstances lead to this error and report to the appropriate person', 'error');
                }
                $scope.isSaving = false;
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.close();
        };

        $scope.clear = function () {
            $scope.encounter = {
                start_date: new Date().setMilliseconds(0),
                case_id: caseId
            };
        };

    }]);
