app.controller('EditEncounterController', [
    '$scope', '$http', '$uibModalInstance', 'encounter', 'caseTypes', 'SweetAlert', '$timeout', 'SummerNoteHelper',
    function ($scope, $http, $uibModalInstance, encounter, caseTypes, SweetAlert, $timeout, SummerNoteHelper) {

        SummerNoteHelper.initialize($scope);

        $scope.encounter            = encounter;
        var start_datetime          = moment(encounter.start_time);
        $scope.encounter.start_date = start_datetime.toDate();
        $scope.admin                = false;
        $scope.caseTypes =             caseTypes;

        $http.get('/user/personal').success(function (r) {
            $scope.admin = r.admin
        });

        $scope.limitTime = function () {
            $scope.encounter.billable_time      = parseFloat(Math.round($scope.encounter.billable_time * 10) / 10);
            $scope.encounter.travel_time        = parseFloat(Math.round($scope.encounter.travel_time * 10) / 10);
            $scope.encounter.documentation_time = parseFloat(Math.round($scope.encounter.documentation_time * 10) / 10);
            $scope.encounter.mileage            = parseFloat(Math.round($scope.encounter.mileage));
        };

        $scope.ok = function () {
            if (!$scope.encounter.type) {
                alert('Please select an encounter type');
                return;
            }

            if ($scope.encounter.mileage >= 600) {
                var conf = confirm("The mileage entered is greater than 600 miles. Is this correct?");

                if (!conf) {
                    return;
                }
            }

            $http.post('/case/edit-encounter', $scope.encounter).success(function (r) {
                $uibModalInstance.close(r);
            }).catch(function (e) {
                if (e.status === 400) {
                    SweetAlert.swal("Error", e.data.error, 'error');
                } else {
                    SweetAlert.swal("Error", 'An unknown error occurred. Please try again. If the problem persists, please document what circumstances lead to this error and report to the appropriate person', 'error');
                }
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.close();
        };

        $timeout(function() {
            jQuery("input").blur();
        }, 1000);
    }]);
