app.controller('ReviewEditController', [
    '$scope', '$http', 'SweetAlert', '$location', '$stateParams',
    function ($scope, $http, SweetAlert, $location, $stateParams)
    {
        $scope.review = {
            case_id: '',
            type: '',
            start: new Date(),
            end: new  Date(),
            update_number: ""
        };

        getData();

        $scope.updateReview = updateReview;
        $scope.deleteReview = deleteReview;

        $scope.$watch('review', getSalesBreakdown, true);

        function getSalesBreakdown()
        {
            if (
                $scope.review.case_id != '' &&
                $scope.review.type != ''
            )
            {
                $http
                    .post('/review/sales-review-breakdown', $scope.review)
                    .success(function (result)
                    {
                        $scope.salesBreakdown = result;
                    });
            }

        }

        function deleteReview()
        {
            if (!confirm("Are you sure you want to delete this review?")) return;

            $http
                .post('review/delete', $scope.review)
                .success(function (result) {
                    SweetAlert.swal("Deleted", "The review has been deleted");
                    $location.path('/review/all');
                }).error(genericErrorHandler);
        }

        function updateReview()
        {
            $http
                .post('review/edit', $scope.review)
                .success(function (result)
                {
                    $location.path('/review/open/' + result.id);
                })
                .error(genericErrorHandler)
        }

        function genericErrorHandler(error, code)
        {
            if (code == 422)
            {
                for (var property in error)
                {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else
            {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }

        function getData()
        {


            $http
                .get('/review/open?id=' + $stateParams.id)
                .success(function (result)
                {
                    console.log(result);
                    $scope.review = result.review;
                    $scope.review.start = moment($scope.review.start).toDate();
                    $scope.review.end = moment($scope.review.end).toDate();
                    $scope.case = result.case;
                    $scope.case.case_information.basic_information = JSON.parse($scope.case.case_information.basic_information);

                    console.log($scope.case.case_information.basic_information.fields.last_name.value + ', ' + $scope.case.case_information.basic_information.fields.first_name.value);
                })
        }
    }]);
