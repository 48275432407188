app.directive('cuiInput', [
    'AutocompleteData', "$uibModal",
    function (AutocompleteData, $uibModal) {
        return {

            scope: {
                field: '=field',
                error: "=error"
            },
            templateUrl: '/templates/directives/cui-input.html',
            controller: ['$scope', '$sce', "SummerNoteHelper", function ($scope, $sce, SummerNoteHelper) {

                $scope.availableUsers     = AutocompleteData.users;
                $scope.availableAddresses = AutocompleteData.addresses;

                $scope.gridSummernoteOptions = {
                    height: 200,
                    width: 330,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
                        ['insert', ['link', 'picture']],
                        ['misc', ['fullscreen']]
                    ],
                    popover: {
                        image: [
                            ['imageSize', ['imageSize100', 'imageSize50', 'imageSize25']],
                            ['remove', ['removeMedia']]
                        ]
                    },
                    callbacks: SummerNoteHelper.defaultOptions.callbacks
                };

                $scope.summerNoteInitialized = false;

                $scope.imageUpload = function(files) {
                    SummerNoteHelper.processImages(files, ($(this)[0].editor));
                }

                var updateDisplayValue = function () {

                    if ($scope.field.type === "addressBook") {
                        var item = AutocompleteData.addresses.filter(function (i) {
                            return i.id === $scope.field.value
                        })[0];
                        if (item) {
                            $scope.field.display_value = item.display;
                        }
                    }
                    if ($scope.field.type === "date") {
                        if ($scope.field.date_value === "Invalid Date" || $scope.field.date_value === null || $scope.field.date_value === undefined) {
                            $scope.field.value = null;
                        } else {
                            $scope.field.value = moment($scope.field.date_value).format("MM/DD/YYYY");
                        }
                    }

                    if (typeof $scope.field['name'] !== 'undefined') {
                        SummerNoteHelper.initialize($scope, {
                            height: 200,
                            targetId: $scope.field.name + "-summernote"
                        });
                    }
                };


                let resizeTextarea = function(textarea) {
                    console.log(textarea.scrollHeight);
                    textarea.style.height = (textarea.scrollHeight) + "px";
                }

                $scope.initializeGrid = function() {

                    //Quick little bit to auto size the textareas on edit properly.

                    let textareas = document.getElementsByClassName("grid-textarea");
                    window.setTimeout(_ => {
                        for (let i = 0; i < textareas.length; i++) {
                            let id = textareas[i].id;
                            $scope.editorInit(id);
                        }
                    }, 15);

                }

                $scope.trustAsHtml = function (value) {
                    if (value == undefined) return '';
                    if (value == null) return '';
                    return $sce.trustAsHtml(value.toString());
                }

                $scope.$watch('field.value', updateDisplayValue);

                if ($scope.field.type === 'addressBook') {
                    $scope.$watch(function () {
                        return AutocompleteData.addresses
                    }, updateDisplayValue, true);
                    $scope.$watch('field.display_value', function () {
                        if ($scope.field.display_value === "") {
                            $scope.field.value = null;
                        }
                    })
                }
                if ($scope.field.type === "date") {
                    $scope.$watch('field.date_value', updateDisplayValue);
                    if ($scope.field.value !== null) {
                        $scope.field.date_value = moment($scope.field.value).toDate();
                    } else {
                        $scope.field_date_value = null;
                    }
                }

                updateDisplayValue();

                $scope.selectedTypeahead = function ($item) {
                    $scope.field.value         = $item.id;
                    $scope.field.display_value = $item.display;
                };

                $scope.addAddressBook = function () {
                    var modal = $uibModal.open({
                        templateUrl: '/templates/addresses/new.html',
                        controller: 'NewAddressController',
                        size: 'lg'
                    });

                    modal.result.then(function (result) {
                        AutocompleteData.getData();
                        $scope.field.value = result.address.id;
                    });
                };

                $scope.searchAddressBook = function () {
                    var modal = $uibModal.open({
                        templateUrl: '/templates/addresses/search-popup.html',
                        controller: 'AddressBookSearchController',
                        size: 'lg'
                    });

                    modal.result.then(function (result) {
                        AutocompleteData.getData();
                        $scope.field.value = result.address.id;
                    });
                };

                $scope.toggleCheckBoxArray = function(option) {
                    let index = $scope.field.value.indexOf(option);

                    if (index === -1) {
                        $scope.field.value.push(option);
                    } else {
                        $scope.field.value.splice(index, 1);
                    }
                }

                let getGridCell = function(row, col){
                    for (let i = 0; i < $scope.field.value[row].length; i++) {
                        if ($scope.field.value[row][i].label == col.label) {
                            return $scope.field.value[row][i].value;
                        }
                    }

                    return false;
                }

                let setGridCell = function(row, col, value) {
                    for (let i = 0; i < $scope.field.value[row].length; i++) {
                        if ($scope.field.value[row][i].label === col.label) {
                            $scope.field.value[row][i].value = value;
                        }

                    }
                }

                $scope.updateGridValue = function(type, row, col) {
                    let input = document.getElementById(type + "_" + row + "_" + col.label);
                    setGridCell(row, col, input.value);
                }

                $scope.updateGridCheckboxCell = function(row,col) {
                    setGridCell(row, col, !getGridCell(row, col));
                }

                $scope.clearGrid = function() {
                    for (let i = 0; i < $scope.field.rows.length; i++) {
                        $scope.field.value[$scope.field.rows[i]].forEach(cell => {
                            if (cell.type == "checkbox") {
                                cell.value = false;
                            }

                            if (cell.type == "textarea") {
                                cell.value = "";
                            }

                            if (cell.type == "text") {
                                cell.value = "";
                            }

                            if (cell.type == "number") {
                                cell.value = 0;
                            }
                        })
                    }
                }

            }]
        }
    }]);
