app.controller('CaseController', [
    '$sce', '$scope', '$http', '$cookieStore', '$stateParams', '$uibModal', 'FileUploader', '$location', 'Title', '$timeout', 'caseParser', "$rootScope", "SweetAlert", "ToggleManager", "SummerNoteHelper",
    function ($sce, $scope, $http, $cookieStore, $stateParams, $uibModal, FileUploader, $location, Title, $timeout, caseParser, $rootScope, SweetAlert, ToggleManager, SummerNoteHelper) {

        SummerNoteHelper.initialize($scope, {
            height: '150px'
        });

        $scope.encounterPage       = 1;
        $scope.encounterTotalItems = 0;
        $scope.encounterPerPage    = 10;

        $scope.toggleManager = ToggleManager;

        $scope.toggleManager.addTab("ot", ["basic_information", "billing", "reports"]);

        var starting_path = $location.path();
        $scope.page_type  = 'case';

        $scope.changeCompany = function () {
            $http.post('/case/change-company', {
                id: $scope.case.id,
                company: $scope.case.company
            });
        };

        $scope.hasCaseType = function(type) {
            return typeof $scope.caseInformation !== 'undefined' ? $scope.caseInformation.basic_information.fields.case_types.value.indexOf(type) !== -1 : false;
        }

        window.onbeforeunload = function () {
            if ($scope.areChanges()) {
                return "There are unsaved changes. Are you sure you want to leave?";
            }
        };

        $scope.$on('$locationChangeStart', function (event) {
            if ($scope.areChanges() && $location.path() !== starting_path) {
                var answer = confirm("There are unsaved changes. Are you sure you want to leave?");
                if (!answer) {
                    event.preventDefault();
                } else {
                    window.onbeforeunload = null;
                }
            }
        });
        $scope.encounterFilter = {
            start: moment().subtract(6, 'months').toDate(),
            end: null,
            cm: '',
            caseType: null,
            includeDeleted: false,
        };
        $scope.reviewType      = "Update";

        $scope.finishedSave = false;

        $scope.$watch('finishedSave', function () {
            if ($scope.finishedSave) {
                $timeout(function () {
                    $scope.finishedSave = false;
                }, 5000);
            }
        });

        $http.get('/user/personal').success(function (response) {
            $scope.name  = response.name;
            $scope.admin = response.admin;
            $scope.my_id = response.my_id;
            $scope.can_audit = response.can_audit;

            getEncounters();
        });

        $scope.toggleAll = function (page) {
            console.log(page)
            var hide = undefined;
            for (var i in page) {
                if (hide == undefined) {
                    hide = !page[i].hidden;
                }
                page[i].hidden = hide;
            }
        };

        $scope.areChanges = function () {
            var pages = ['caseInformation', 'clientProfile', 'clientGoals', 'preFunctionalSections', 'postFunctionalSections', 'otInformation'];
            for (var j in pages) {
                var page = pages[j];
                for (var i in $scope[page]) {
                    if ($scope[page][i].editing) {
                        return true;
                    }
                }
            }
            return false;
        };

        $scope.isArray = function (section) {
            return Array.isArray(section.fields);
        };

        $scope.isField = function (field) {
            return typeof field['name'] !== 'undefined' && typeof field['value'] !== 'undefined' && typeof field['type'] !== 'undefined';
        }

        $scope.saveAll = function (page) {
            if (page != 'all') {
                for (var i in $scope[page]) {
                    if ($scope[page][i].editing) {
                        var savePage = page;
                        if (page == 'preFunctionalSections' || page == 'postFunctionalSections') {
                            savePage = 'functionalAssessments';
                        }
                        $scope.save(savePage, $scope[page][i]);
                    }
                }
            } else {
                var pages = ['caseInformation', 'clientProfile', 'clientGoals', 'preFunctionalSections', 'postFunctionalSections', 'clientSkills', 'otInformation'];
                for (var j in pages) {
                    var page = pages[j];
                    for (var i in $scope[page]) {
                        if ($scope[page][i].editing) {
                            var savePage = page;
                            if (page == 'preFunctionalSections' || page == 'postFunctionalSections') {
                                savePage = 'functionalAssessments';
                            }
                            $scope.save(savePage, $scope[page][i]);
                        }
                    }
                }
            }
        };

        $scope.cancelAll = function (page) {
            if (page != 'all') {
                for (var i in $scope[page]) {
                    if ($scope[page][i].editing) {
                        if (page == 'preFunctionalSections' || page == 'postFunctionalSections') {
                            $scope.cancelEditFunctional($scope[page][i]);
                        } else {
                            $scope.cancelEdit($scope[page][i]);
                        }
                    }
                }
            } else {
                var pages = ['caseInformation', 'clientProfile', 'clientGoals', 'preFunctionalSections', 'clientSkills', 'otInformation'];
                for (var j in pages) {
                    var page = pages[j];
                    for (var i in $scope[page]) {
                        if ($scope[page][i].editing) {
                            if (page == 'preFunctionalSections' || page == 'postFunctionalSections') {
                                $scope.cancelEditFunctional($scope[page][i]);
                            } else {
                                $scope.cancelEdit($scope[page][i]);
                            }
                        }
                    }
                }
            }
        };

        $scope.addMedRequestFromEncounter = function (encounter) {

            var modal = $uibModal.open({
                templateUrl: '/templates/case/med-request-popup.html',
                controller: [
                    "$scope", "$uibModalInstance",
                    function ($innerScope, $uibModalInstance) {
                        $innerScope.provider        = {
                            type: 'addressBook',
                            value: null
                        };
                        $innerScope.details         = {
                            type: 'text',
                            value: null
                        };
                        $innerScope.date_of_service = {
                            type: 'date',
                            value: moment(encounter.start_time).format("MM/DD/YYYY")
                        };
                        $innerScope.date_requested  = {
                            type: 'date',
                            value: moment(encounter.start_time).format("MM/DD/YYYY")
                        };
                        $innerScope.is_stat = {
                            type: "checkbox",
                            value: false
                        };
                        $innerScope.finish = function () {
                            $uibModalInstance.close({
                                provider: $innerScope.provider,
                                details: $innerScope.details,
                                date_of_service: $innerScope.date_of_service,
                                date_requested: $innerScope.date_requested,
                                is_stat: $innerScope.is_stat,
                            });
                        };
                        $innerScope.cancel = function () {
                            $uibModalInstance.dismiss();
                        };
                    }],
                size: 'md',
                backdrop: 'static'
            });
            modal.result.then(function (request) {
                var template = angular.merge({}, $scope.caseInformation.medical_requests.fields_template);
                template.provider.value = request.provider.value;
                template.details.value = request.details.value;
                template.date_of_service.value = request.date_of_service.value;
                template.date_requested.value = request.date_requested.value;
                template.requester.value = $rootScope.name;
                template.is_stat.value = request.is_stat.value;
                $scope.caseInformation.medical_requests.fields.push(template);
                $scope.caseInformation.medical_requests.editing = true;
                $scope.save('caseInformation', $scope.caseInformation.medical_requests);
            });
        };

        $scope.cancelEditFunctional = function (section) {
            if (section.previous_status) {
                section.status = angular.copy(section.previous_status);
                delete section.previous_status;
            }
            if (section.previous_comments) {
                section.comments = angular.copy(section.previous_comments);
                delete section.comments;
            }
            section.editing = false;
        };

        $scope.cancelEdit = function (section) {
            if (section.previous_fields) {
                section.fields = angular.copy(section.previous_fields);
                delete section.previous_fields;
            } else {
                SweetAlert.swal('Error', "There was an error restoring the previous information for " + section, 'error');
            }
            section.editing = false;
        };

        $scope.editingFunctional = function (section) {
            section.previous_status   = angular.copy(section.status);
            section.previous_comments = angular.copy(section.comments);
            section.editing           = true;
        };

        $scope.editing = function (section) {
            section.previous_fields = angular.copy(section.fields);
            section.editing = true;
        };

        $scope.gotoEncounterTop = function () {
            jQuery("body").animate({scrollTop: jQuery("#encountertop").offset().top}, "slow");
        };

        let clearSectionErrors = function(section) {
            for (let subSection in $scope[section]) {

                for (let fieldName in $scope[section][subSection].fields) {
                    if (!$scope.isField($scope[section][subSection].fields[fieldName])) {
                        for (let subFieldName in $scope[section][subSection].fields[fieldName]) {
                            $scope[section][subSection].fields[fieldName][subFieldName].error = false;
                        }
                    } else {
                        $scope[section][subSection].fields[fieldName].error = false;
                    }

                }
            }
        }

        $scope.save = function (type, section) {
            section.saving = true;
            let data = angular.copy($scope[type]);
            console.log(data)

            if (type === 'functionalAssessments') {
                data = {
                    preFunctionalSections: angular.copy($scope.preFunctionalSections),
                    postFunctionalSections: angular.copy($scope.postFunctionalSections)
                };

                for (var key in data.preFunctionalSections) {
                    if (data.preFunctionalSections.hasOwnProperty(key)) {
                        if (!data.preFunctionalSections[key].editing) {
                            delete data.preFunctionalSections[key];
                        }
                    }
                }
                for (var key in data.postFunctionalSections) {
                    if (data.postFunctionalSections.hasOwnProperty(key)) {
                        if (!data.postFunctionalSections[key].editing) {
                            delete data.postFunctionalSections[key];
                        }
                    }
                }

            } else {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (!data[key].editing) {
                            delete data[key];
                        }
                    }
                }
            }

            clearSectionErrors(type);

            console.log($scope.case, type, data)

            $http.post('/case/save', {
                claim: $scope.case,
                section: type,
                section_data: data
            }).then(function (r) {
                section.saving = false;

                if (!r.data.success) {
                    for (let i = 0; i < r.data.errors.length; i++) {
                        let error = r.data.errors[i];
                        $scope[error.section][error['sub-section']]['fields'][error.field].error = error.error;
                    }
                    //alert("There was an unexpected error saving your work. Please contact Patti and report this error.");
                    alert("There were errors in your work, please review the form again");
                    return;
                } else {
                    if (section && section.hasOwnProperty('previous_fields')) {
                        delete section.previous_fields;
                    }

                    if (section) {
                        section.editing = false;
                    }
                }
            }, function () {
                section.saving = false;
                alert("Unable to contact the server to save. Please try to save again.");
            });
        };

        $scope.trustAsHtml = function (string) {
            return $sce.trustAsHtml(string);
        };

        $scope.toggleSection = function (section) {
            if (!section.hidden) {
                section.hidden = true;
            } else {
                section.hidden = false;
            }
        };

        let getReports = function() {
            $http.get('/case/single/' + $stateParams.id).success(function (c) {
                $scope.case = c;
                $scope.med_report = c.reports.filter(function (report) {
                    return report.type == 'medication';
                }).pop();
                $scope.dfs_report = c.reports.filter(function (report) {
                    return report.type == 'dfs';
                }).pop();

                $scope.has_dfs_report = typeof $scope.dfs_report !== "undefined";
                $scope.has_med_report = typeof $scope.med_report !== "undefined";
            });
        }

        $scope.generateReport = function(type) {
            window.open("report/" + type + "/" + $scope.case.id, "_blank");
            setTimeout(_ => {
                getReports();
            }, 500);
        }

        $http.get('/case/single/' + $stateParams.id).success(function (c) {
            $scope.case       = c;
            $scope.med_report = c.reports.filter(function (report) {
                return report.type == 'medication';
            }).pop();
            $scope.dfs_report = c.reports.filter(function (report) {
                return report.type == 'dfs';
            }).pop();

            $scope.has_dfs_report = typeof $scope.dfs_report !== "undefined";
            $scope.has_med_report = typeof $scope.med_report !== "undefined";

            var data = caseParser(c);

            $scope.caseInformation        = data.caseInformation;
            $scope.clientProfile          = data.clientProfile;
            $scope.clientGoals            = data.clientGoals;
            $scope.preFunctionalSections  = data.preFunctionalSections;
            $scope.postFunctionalSections = data.postFunctionalSections;
            $scope.clientSkills           = data.clientSkills;
            $scope.otInformation          = data.otInformation;

            for (var i in $scope.clientProfile) {
                $scope.clientProfile[i].hidden  = true;
                $scope.clientProfile[i].editing = false;
            }

            for (var i in $scope.clientGoals) {
                $scope.clientGoals[i].hidden  = true;
                $scope.clientGoals[i].editing = false;
            }

            for (var i in $scope.preFunctionalSections) {
                $scope.preFunctionalSections[i].hidden  = true;
                $scope.preFunctionalSections[i].editing = false;
            }
            for (var i in $scope.postFunctionalSections) {
                $scope.postFunctionalSections[i].hidden  = true;
                $scope.postFunctionalSections[i].editing = false;
            }

            for (var i in $scope.caseInformation) {
                $scope.caseInformation[i].hidden  = true;
                $scope.caseInformation[i].editing = false;
            }

            for (var i in $scope.clientSkills) {
                $scope.clientSkills[i].hidden = true;
                $scope.clientSkills[i].editing = false;
            }

            for (let i in $scope.otInformation) {

                $scope.otInformation[i].hidden = true;
                $scope.otInformation[i].editing = false;
            }
            console.log($scope.otInformation);

            var name = $scope.caseInformation.basic_information.fields.last_name.value + ', ' + $scope.caseInformation.basic_information.fields.first_name.value;
            Title.setTitle(name);
        });

        $scope.getEncounters = getEncounters;

        function getEncounters() {
            let url = '/case/encounters/' + $stateParams.id + '?';
            if ($scope.can_audit) {
                url += "&include_deleted=true";
            }
            if ($scope.encounterFilter.start) {
                const start = moment($scope.encounterFilter.start).format("YYYY-MM-DD");
                url += "&start_date=" + encodeURIComponent(start);
            }
            if ($scope.encounterFilter.end) {
                const end = moment($scope.encounterFilter.end).format("YYYY-MM-DD");
                url += "&end_date=" + encodeURIComponent(end);
            }
            $http.get(url).success(function (encounters) {
                $scope.case.encounters = encounters;
                $scope.filterEncounters();
            });
        }

        $scope.$watch('encountersPerPage', function () {
            $scope.filterEncounters();
        });

        $scope.filterEncounters = function () {
            if (!$scope.case) {
                return;
            }

            $scope.filteredEncounters = [];
            var newEncounters         = [];
            for (var i in $scope.case.encounters) {
                var encounter = $scope.case.encounters[i];
                var name      = encounter.user.first_name + ' ' + encounter.user.last_name;

                if (!$scope.encounterFilter.includeDeleted && !!encounter.deleted_at) {
                    continue;
                }

                if ($scope.encounterFilter.cm) {
                    if (name.toLowerCase().indexOf($scope.encounterFilter.cm.toLowerCase()) === -1) {
                        continue;
                    }
                }

                if ($scope.encounterFilter.caseType) {
                    if ($scope.encounterFilter.caseType != encounter.case_type) {
                        continue;
                    }
                }

                newEncounters.push(encounter);
            }

            i     = 0;
            var t = newEncounters.length;
            while (i < t) {
                $scope.filteredEncounters.push(newEncounters.slice(i, i + $scope.encounterPerPage));
                i += $scope.encounterPerPage;
            }
            $scope.encounterPage       = 1;
            $scope.encounterTotalItems = t;
        };

        $scope.showAuditLogs = function(encounter) {
            $http.get("/case/encounters-audit-log/" + encounter.id).success((res) => {
                console.log(res);
                var modal = $uibModal.open({
                    templateUrl: '/templates/auditLogs/encounterList.html',
                    size: 'lg',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        options: function() {
                            return {
                                logs: res,
                            }
                        }
                    }, controller: [
                        "$scope", "$uibModalInstance", "options",
                        function ($innerScope, $uibModalInstance, options) {
                            $innerScope.logs = options.logs;
                            console.log(options);
                            $innerScope.close = function () {
                                $uibModalInstance.dismiss();
                            };
                        }]
                })
            }).catch((err) => {
                console.log(err);
            })
        }

        $scope.addEncounter = function () {
            let case_info = $scope.caseInformation.basic_information.fields;
            var modal = $uibModal.open({
                templateUrl: '/templates/case/add-encounter.html',
                controller: 'AddEncounterController',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    options: function () {
                        return {
                            caseId: $scope.case.id,
                            caseTypes: case_info.case_types
                        }
                    }
                }
            });
            modal.result.then(function (encounter) {
                if (encounter) {
                    if (typeof $scope.case.encounters !== 'undefined') {
                        $scope.case.encounters.unshift(encounter);
                    } else {
                        $scope.case.encounters = [encounter];
                    }
                    $scope.filterEncounters();
                }
            });
        };

        $scope.editEncounter = function (encounter) {
            var currentEncounter = angular.merge({}, encounter);

            var case_info = $scope.caseInformation.basic_information.fields;
            var modal            = $uibModal.open({
                templateUrl: '/templates/case/edit-encounter.html',
                controller: 'EditEncounterController',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    encounter: function () {
                        return currentEncounter;
                    },
                    caseTypes: function() {
                        return case_info.case_types;
                    }
                }
            });
            modal.result.then(function (newEncounter) {
                if (newEncounter) {
                    angular.merge(encounter, newEncounter);
                    $scope.filterEncounters();
                }
            });
        };

        $scope.deleteEncounter = function (encounter) {
            if (!confirm('Are you sure you want to delete this encounter?')) {
                return;
            }
            $http.post('/case/delete-encounter', {id: encounter.id}).success(function (res) {
                if (res.success) {
                    getEncounters();
                } else {
                    SweetAlert.swal("Error", res.error, "error");
                }
            });
        };

        $scope.clearEncounterFilter = function () {
            $scope.encounterFilter = {
                start: null,
                end: null,
                cm: ''
            };

            $scope.filterEncounters();
        };

        $scope.addRow = function (section) {
            section.fields.push(angular.merge({}, section.fields_template));
        };

        $scope.encounters = [];

        let today = new Date();

        $scope.invoice = {
            case_id: $stateParams.id,
            invoice_type: "Occupational Therapist",
            start_date: new Date(today.getFullYear(), today.getMonth(), 1),
            end_date: new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59)
        };

        $scope.invoices = [];

        $scope.invoiceEncounters = [];
        $scope.invoiceBreakdown = false;

        let getDatetime = function(time) {
            return moment(time).format("YYYY-MM-DD HH:mm:ss");
        }

        let getInvoiceEncounters = function() {
            $scope.invoiceBreakdown = false;
            $http.get('/case/encounters-summary/' + $stateParams.id + "?case_type=" + $scope.invoice.invoice_type + "&start_date=" + getDatetime($scope.invoice.start_date) + "&end_date=" + getDatetime($scope.invoice.end_date)).success(function (data) {
                $scope.invoiceEncounters = data.encounters;
                delete data.encounters;
                $scope.invoiceBreakdown = data;
            });
        }

        let getCaseInvoices = function() {

            let displayFormat = "L LTS";

            $http.get('/case/' + $stateParams.id + '/invoices')
                .success( invoices => {
                    invoices.forEach(invoice => {
                        invoice.start = moment(invoice.start).format(displayFormat);
                        invoice.end = moment(invoice.end).format(displayFormat);
                        invoice.created_at = moment(invoice.created_at).format(displayFormat);
                    })
                    $scope.invoices = invoices;
                })

        }

        let getNextInvoiceDateRange = function() {
            $http.get("/invoice/next-date-range?case_id=" + $stateParams.id + "&invoice_type=" + $scope.invoice.invoice_type)
                .success(range => {
                    let start = moment(range.start).toDate();
                    let end = moment(range.end).toDate();
                    $scope.invoice.start_date = start;
                    $scope.invoice.end_date = end;
                })
        }

        $scope.initializeInvoice = function() {
            getInvoiceEncounters();
            getCaseInvoices();
            getNextInvoiceDateRange();
        }

        $scope.initializeInvoice();


        $scope.$watch('invoice.start_date', function() {
            getInvoiceEncounters();
        });

        $scope.$watch('invoice.end_date', function() {
            getInvoiceEncounters();
        })

        $scope.createBill = function() {

            let invoice = {...$scope.invoice};

            invoice.start_date = moment($scope.invoice.start_date).format("YYYY-MM-DD HH:mm:ss");
            invoice.end_date = moment($scope.invoice.end_date).format("YYYY-MM-DD HH:mm:ss");

            $http.post('/invoice', invoice)
                .then(function() {
                    getCaseInvoices();
                    getNextInvoiceDateRange();
                    SweetAlert.swal("Success", "Occupational Therapist Bill Created", "success");
                })
                .catch(function() {
                    console.warn("Failed to save invoice");
                    SweetAlert.swal("Error", "Occupational Therapist Bill Failed to Save", "error");
                })

        }

        $scope.voidBill = function(id) {
            let voidRequest = {
                id: id,
                status: "void"
            };

            $http.post("/invoice/status", voidRequest)
                .success(function(res) {
                    getCaseInvoices();
                    getNextInvoiceDateRange();
                    SweetAlert.swal("Success", "Occupational Therapist Bill Voided", "success");
                })
        }

        $scope.OTReports = [];

        let getOtReports = function() {
            $http.get("/report/ot/?case_id=" + $stateParams.id)
                .then(function(r) {
                    $scope.OTReports = r.data.map((report) => {
                        report.content = JSON.parse(report.content);
                        console.log(report);
                        return report;
                    })
                })
        }

        $scope.openOtReportBuilder = function(report = null) {

            let options = {
                caseId: $stateParams.id
            }

            if (report != null) {
                options['report'] = report;
            }

            $uibModal.open({
                templateUrl: '/templates/report/ot-report-builder.html',
                controller: 'OtReportBuilderController',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    options: function() {
                        return options
                    }
                }
            }).result.then((reportWasGenerated) => {
                if (reportWasGenerated) {
                    getOtReports()
                }
            })
        }

        getOtReports();

        $scope.editOTReport = function(report) {
            $scope.openOtReportBuilder(report)
        }

        $scope.deleteOTReport = function(report) {
            $http.delete("/report/ot/" + report.id)
                .then(() => {
                    getOtReports();
                })
                .catch((err) => {
                    console.warn("Error deleting report: " + err);
                })
        }

        $scope.previewOTReport = function(report) {
            window.open("/report/generate-ot-report-preview/" + report.id, "_blank");
        }

        $scope.downloadOTReport = function(report) {
            window.open("/report/generate-ot-report-download/" + report.id, "_blank");
        }

        $scope.downloadOTReportFinal = function(report) {
            window.open("/report/ot-report-final/" + report.id, "_blank");
        }

        $scope.OTHomeModReports = [];

        let openOtHomeModificationReportModal = function(modalOptions) {
            let modal = $uibModal.open({
                templateUrl: '/templates/report/ot-home-modification.html',
                controller: 'AddOtHomeModificationReportController',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    options: function() {
                        return modalOptions
                    }
                }
            });

            modal.result.then(function(reportWasGenerated) {
                if (reportWasGenerated) {
                    getOtHomeModificationReports();
                }
            });
        }

        $scope.addOtHomeModificationReport = function() {
            openOtHomeModificationReportModal({
                caseId: $stateParams.id
            });
        }

        $scope.editOtHomeModificationReport = function(report) {
            openOtHomeModificationReportModal({
                caseId: $stateParams.id,
                report: report
            })
        }

        $scope.deleteOtHomeModificationReport = function(reportId) {
            $http.delete("/report/ot-home-modification/" + reportId)
                .then(function(r) {
                    SweetAlert.swal("Success", "Report was deleted successfully", "success");
                    getOtHomeModificationReports();
                })
                .catch(function(e) {
                    SweetAlert.swal("Error", "Report failed to generate\n\nError Code:" + e.status + "\nError: " + e.statusText, "error");
                })
        }

        $scope.previewOtHomeModificationReport = function(reportId) {
            window.open("/report/ot-home-modification-preview/" + reportId, "_blank");
        }

        $scope.downloadOtHomeModificationReport = function(reportId) {
            window.open("/report/ot-home-modification-download/" + reportId, "_blank");
        }

        let getOtHomeModificationReports = function() {
            $http.get("/report/ot-home-modification/?case_id=" + $stateParams.id)
                .then(function(r) {
                    $scope.OTHomeModReports = r.data;
                    $scope.OTHomeModReports.map(report => {
                        report['expanded'] = false;
                    })
                })
        }

        getOtHomeModificationReports();

        $scope.expandInstructions = function(report) {
            report.expanded = true;
        }

        $scope.collapseInstructions = function(report) {
            report.expanded = false;
        }

    }]);
