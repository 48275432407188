app.controller('OtReportBuilderController', [
    '$scope', '$http', '$uibModalInstance', 'options', "SweetAlert", "SummerNoteHelper",
    function ($scope, $http, $uibModalInstance, options, SweetAlert, SummerNoteHelper) {

        $scope.caseId = options.caseId;

        console.log(options);

        $scope.reportTitle = options.report ? options.report.content.title : "Home Assessment Report";

        $scope.reportId = options.report ? options.report.id : null;

        console.log($scope.reportId);

        $scope.fieldOptions = options.report ? options.report.content.report_fields : {
            header: true,
            contact_info: true,
            assigner_address: true,
            rx_information: true,
            billing: true,
            home_assessment: true,
            home_assessment_subfields: {
                room_notes: true,
                description_of_home: true,
                introduction: true,
                summary: true,
                recommendation: true
            },
            dme: false,
            attendant_care: false
        }

        $scope.reportOptions = {
            billingType: options.report ? options.report.content.billing_type : "bill",
            selectedBill: options.report ? options.report.content.bill_id : null,
            selectedEncounters: []
        }

        $scope.billingTypes = [
            {id: 'bill', label: 'Select from list of Bills'},
            {id: 'encounters', label: "Select from list of Encounters"}
        ]

        $scope.updateSelectedBill = function(billId) {
            $scope.reportOptions.selectedBill = billId
            $scope.bills.forEach((bill) => {
                if (bill.id == $scope.reportOptions.selectedBill) {
                    bill.selected = true
                    $scope.reportOptions.selectedEncounters = bill.encounters;
                } else {
                    bill.selected = false
                }
            })
        }

        const updateReportTitle = function() {
            if ($scope.fieldOptions.home_assessment) {
                $scope.reportTitle = "Home Assessment Report";
            }
            else if ($scope.fieldOptions.dme || $scope.fieldOptions.attendant_care) {
                if ($scope.fieldOptions.dme && $scope.fieldOptions.attendant_care) {
                    $scope.reportTitle = "Durable Medical Equipment & Attendant Care Evaluation Report";
                } else if ($scope.fieldOptions.dme) {
                    $scope.reportTitle = "Durable Medical Equipment Report";
                } else {
                    $scope.reportTitle = "Attendant Care Evaluation Report";
                }
            } else {
                $scope.reportTitle = "OT Report";
            }
        }

        $scope.$watch('fieldOptions.home_assessment', (options) => {
            updateReportTitle();
        })

        $scope.$watch('fieldOptions.dme', (options) => {
            updateReportTitle();
        })

        $scope.$watch('fieldOptions.attendant_care', (options) => {
            updateReportTitle();
        })


        $scope.bills = [];
        $scope.encounters = [];

        $scope.updateData = function() {
            $http.get("/case/" + $scope.caseId + "/invoices?exclude=void")
                .then((res) => {
                    $scope.bills = res.data
                    if ($scope.reportOptions.billingType == "bill") {
                        $scope.bills.forEach((bill) => {
                            if (bill.id == $scope.reportOptions.selectedBill) {
                                bill.selected = true;
                                $scope.reportOptions.selectedEncounters = bill.encounters;
                            } else {
                                bill.selected = false;
                            }
                        })
                    }
                })

            $http.get("/case/encounters/" + $scope.caseId + "?case_type=Occupational Therapist")
                .then((res) => {
                    $scope.encounters = res.data

                    if ($scope.reportOptions.billingType == "encounters") {
                        $scope.reportOptions.selectedEncounters = [];
                        $scope.encounters.forEach((encounter) => {
                            if (options.report.content.encounter_ids.indexOf(encounter.id) !== -1) {
                                encounter.selected = true;
                                $scope.reportOptions.selectedEncounters.push(encounter);
                            } else {
                                encounter.selected = false;
                            }
                        })
                    }
                })
        }

        $scope.updateData();

        $scope.subtype = "custom"

        const throwError = function(error) {
            SweetAlert.swal("Report Builder Error", error + " Please correct this mistake and try again", "error");
        }

        $scope.addReport = function() {
            let encounters = [];
            let validReport = true;
            let error = "";

            if ($scope.fieldOptions.billing) {

                console.log("Checking billing")
                console.log($scope.reportOptions)
                if ($scope.reportOptions.billingType == "bill") {
                    encounters = $scope.reportOptions.selectedEncounters;
                } else {
                    $scope.encounters.forEach((encounter) => {
                        if (encounter.selected) {
                            encounters.push(encounter);
                        }
                    })
                }

                if (encounters.length == 0) {
                    validReport = false;
                    error ="You've chosen to add billing to this report, but no Bill or Encounter(s) are selected.";
                } else {
                    encounters = encounters.map((encounter) => {
                        return encounter.id
                    })
                }
            }

            if ($scope.reportTitle.trim() == "") {
                validReport = false;
                error = "Report title can not be left empty."
            }

            if (validReport) {
                let request = {
                    case_id: $scope.caseId,
                    title: $scope.reportTitle,
                    billing_type: $scope.reportOptions.billingType,
                    report_fields: $scope.fieldOptions,
                    encounter_ids: encounters,
                    bill_id: $scope.reportOptions.selectedBill
                }

                if ($scope.reportId == null) {
                    $http.post("/report/ot", request)
                        .then((data) => {
                            SweetAlert.swal("Report Created Successfully!", "success")
                            $scope.closeModal(true)
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                } else {
                    request['id'] = $scope.reportId;
                    console.log(request);
                    $http.put("/report/ot", request)
                        .then((data) => {
                            console.log(data);
                            SweetAlert.swal("Report Updated Successfully!", "success")
                            $scope.closeModal(true)
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
            } else {
                throwError(error);
            }
        }

        $scope.updateHomeAssessmentSubfields = function() {
            //Override sub fields when manually clicked
            for (let field in $scope.fieldOptions.home_assessment_subfields) {
                $scope.fieldOptions.home_assessment_subfields[field] = $scope.fieldOptions.home_assessment;
            }
        }

        $scope.updateHomeAssessmentCheckbox = function() {
            let hasAny = false
            let empty = true

            for (let field in $scope.fieldOptions.home_assessment_subfields) {
                if ($scope.fieldOptions.home_assessment_subfields[field]) {
                    hasAny = true
                    empty = false
                }
            }

            if (empty) {
                $scope.fieldOptions.home_assessment = false;
            }

            if (hasAny) {
                $scope.fieldOptions.home_assessment = true;
            }
        }

        $scope.name = "";
        $scope.fields = [];
        $scope.encounters = [];

        if (typeof options.report === "undefined") {
            $scope.instructions = "";
            $scope.editing = false;
        } else {
            $scope.instructions = options.report.instructions;
            $scope.reportId = options.report.id;
            $scope.editing = true;
        }

        $scope.testing = false;

        $scope.closeModal = function(createdReport = false) {
            $uibModalInstance.close(createdReport);
        }
    }]
);
