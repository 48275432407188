app.controller('MedicalRequestsController', [
    '$scope', '$http', 'SweetAlert', 'AutocompleteData', 'DTOptionsBuilder', '$uibModal',
    function ($scope, $http, SweetAlert, AutocompleteData, DTOptionsBuilder, $uibModal) {
        $scope.getAddress = getAddress;
        $scope.setFaxed = setFaxed;
        $scope.setReceived = setReceived;
        $scope.setNotReceived = setNotReceived;
        $scope.openFax = openFax;

        $scope.params = {
            service_start: null,
            service_end: null,
            show_all: 0
        };

        $scope.dtOptions           = new DTOptionsBuilder.newOptions();
        $scope.dtOptions.searching = false;
        $scope.dtOptions.paging    = false;

        // getRequests();

        $scope.$watch('params', getRequests, true);

        function openFax(row)
        {
            var modal = $uibModal.open({
                templateUrl: '/templates/report/medical-requests-fax-prompt.html',
                controller: [
                    '$scope', '$uibModalInstance', 'name', 'date_of_service',
                    function ($scope, $uibModalInstance, name, date_of_service) {
                        var dos = moment(date_of_service).subtract(1, 'days').format("M/DD/YYYY");
                        $scope.memo = "<p>Could you please fax to this office the report from " + name + " on " + dos + "?</p>";
                        $scope.memo += "<br /><br />";
                        $scope.memo += '<p style="font-style: italic; text-align: justify">\
                                            If there will be a cost incurred to obtain this report, you must receive prior authorization for\
                                            payment. Do not send the report without this prior payment authorization. Please\
                                        call 248-633-2855.<br />\
                                        <br />\
                                        Thank You\
                                        </p>';
                        $scope.pages = 3;

                        $scope.generate = function () {
                            $uibModalInstance.close({
                                memo: $scope.memo,
                                pages: $scope.pages
                            });
                        }

                        $scope.cancel = function () {
                            $uibModalInstance.close();
                        }
                    }],
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    name: function ()
                    {
                        return row.name;
                    },
                    date_of_service: function ()
                    {
                        return row.date_of_service;
                    }
                }
            });

            modal.result.then(function (d) {
                if (d) {
                    var params = jQuery.param({
                        claim: row.claim,
                        index: row.index,
                        pages: d.pages,
                        memo: d.memo
                    });

                    window.open("report/medical-request-fax?" + params);
                }
            });

        }

        function setReceived(row)
        {
            $http
                .post('report/medical-request-received', {
                    claim: row.claim,
                    index: row.index
                })
                .success(function () {
                    SweetAlert.swal('Success', row.name + " was set as received.", 'success');
                    getRequests();
                })
        }

        function setNotReceived(row)
        {
            var modal = $uibModal.open({
                templateUrl: '/templates/report/medical-requests-not-received-prompt.html',
                controller: function ($scope, $uibModalInstance) {
                    $scope.save = function () {
                        $uibModalInstance.close($scope.reason);
                    };

                    $scope.cancel = function () {
                        $uibModalInstance.close();
                    }
                }
            });

            modal.result.then(function (reason) {

                if (reason) {
                    $http
                        .post('report/medical-request-not-received', {
                            claim: row.claim,
                            index: row.index,
                            reason: reason
                        })
                        .success(function () {
                            SweetAlert.swal('Success', row.name + " was set as not received.", 'success');
                            getRequests();
                        })
                }


            })
        }

        function setFaxed(row) {
            var modal = $uibModal.open({
                templateUrl: '/templates/case/add-encounter.html',
                controller: 'AddEncounterController',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    options: function () {
                        return {
                            caseId: row.claim,
                            type: "Fax",
                            billable_time: 0.2,
                            caseTypes: row.case_types
                        }
                    }
                }
            });
            modal.result.then(function (encounter)
            {
                if (encounter)
                {
                    $http
                        .post('report/medical-request-faxed', {
                            claim: row.claim,
                            index: row.index,
                            date_faxed: encounter.start_time
                        })
                        .success(function () {
                            SweetAlert.swal('Success', row.name + " was set as faxed.", 'success');
                            getRequests();
                        })
                }
            });
        }

        function getAddress(id) {
            for (var i in AutocompleteData.addresses) {
                if (AutocompleteData.addresses[i].id === id) {
                    return AutocompleteData.addresses[i].display;
                }
            }
        }

        function getRequests() {
            var params = jQuery.param($scope.params);
            $http
                .get('report/open-medical-request?' + params)
                .success(function (data) {
                    $scope.data = data;
                    $scope.data.forEach(function (row) {
                        if (row.date_requested) {
                            row.date_requested = moment(row.date_requested).add(1, 'days').toDate();
                        }
                        if (row.date_of_service) {
                            row.date_of_service = moment(row.date_of_service).add(1, 'days').toDate();
                        }
                        if (row.date_faxed) {
                            row.date_faxed = moment(row.date_faxed).toDate();
                        }
                        if (row.date_received) {
                            row.date_received = moment(row.date_received).add(1, 'days').toDate();
                        }
                    })
                }).error(genericErrorHandler);
        }

        function genericErrorHandler(error, code) {
            if (code == 422) {
                for (var property in error) {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }
    }]);
