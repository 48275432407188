app.directive('cuiField', function () {
    return {
        scope: {
            field: '=field'
        },
        templateUrl: '/templates/directives/cui-field.html',
        controller: ['$scope', 'AutocompleteData', '$sce', "$uibModal",
            function ($scope, AutocompleteData, $sce, $uibModal) {

            $scope.availableUsers = AutocompleteData.users;

            console.log($scope.field);

            $scope.parseValue = function(row, col) {
                let cell = null;
                $scope.field.value[row].forEach(c => {
                    if (c.label == col.label) {
                        cell = c;
                    }
                });

                switch(cell.type) {
                    case 'text':
                        return cell.value;
                    case 'textarea':
                        return cell.value;
                    case 'checkbox':
                        return cell.value ? 'X' : '';
                    default:
                        return "";
                }
            }

            var updateDisplayValue = function () {
                if ($scope.field.value == null || $scope.field.value == undefined || $scope.field.value == '') {
                    $scope.display_value = '';
                    return;
                }

                if ($scope.field.type == 'date') {
                    if (moment.invalid(moment($scope.field.value))) {
                        $scope.display_value = moment($scope.field.value).format('MM/DD/YYYY')

                    }
                } else
                if ($scope.field.type == "addressBook") {
                    if (!$scope.field.value) {
                        return;
                    }

                    var found = false;

                    for (var i in AutocompleteData.addresses) {
                        if (AutocompleteData.addresses[i].id == $scope.field.value) {
                            found = true;
                            $scope.display_value = AutocompleteData.addresses[i].display;
                            $scope.addressbook = AutocompleteData.addresses[i];
                            break;
                        }
                    }

                    if (!found) {
                        $scope.display_value = $scope.field.value;
                    }
                } else {
                    $scope.display_value = $scope.field.value;
                }
            };

            $scope.$watch('field.value', updateDisplayValue);

            if ($scope.field.type == 'addressBook') {
                $scope.$watch(function () { return AutocompleteData.addresses }, updateDisplayValue, true);
            }

            updateDisplayValue();

            $scope.trustAsHtml = function (value) {
                if (value == undefined) return '';
                if (value == null) return '';
                return $sce.trustAsHtml(value.toString());
            }
            $scope.openAddressEdit = function(address) {
                console.log(address);
                $uibModal.open({
                    controller: "EditAddressController",
                    templateUrl: '/templates/addresses/edit.html',
                    size: 'lg',
                    resolve: {
                        addressId: function () { return address.id; }
                    }
                }).closed.then(() => {
                    AutocompleteData.getData();
                });
            }
        }]
    }
});
