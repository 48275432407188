app.controller('DashboardController', [
    '$scope', '$http', '$cookieStore', '$location', 'Title',
    function ($scope, $http, $cookieStore, $location, Title)
    {
        Title.setTitle("Dashboard");

        $http
            .get('/dashboard/my-pending-reviews')
            .success(function (result) {
                $scope.myPendingReviews = result;
            });
    }]);
