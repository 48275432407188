app.directive('lazytabset', ['$location', function ($location) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        controller: function($scope) {
            $scope.templateUrl = '';
            var tabs = $scope.tabs = [];
            var controller = this;

            this.selectTab = function (tab) {
                angular.forEach(tabs, function (tab) {
                    tab.selected = false;
                });
                tab.selected = true;
            };

            this.setTabTemplate = function (templateUrl) {
                $scope.templateUrl = templateUrl;
            }

            this.addTab = function (tab) {
                if (tabs.length == 0 || ($location.search().tab && $location.search().tab.toLowerCase() == tab.title.toLowerCase())) {
                    controller.selectTab(tab);
                }
                tabs.push(tab);
            };
        },
        template:
        '<div class="box-tab">' +
        '<div class="nav nav-tabs" ng-transclude></div>' +
        '<div class="tab-content">' +
        '<ng-include src="templateUrl">' +
        '</ng-include>' +
        '</div>' +
        '</div>'
    };
}])
    .directive('lazytab', ['$location', function ($location) {
        return {
            restrict: 'E',
            replace: true,
            require: '^lazytabset',
            scope: {
                title: '@',
                templateUrl: '@'
            },
            link: function(scope, element, attrs, tabsetController) {
                tabsetController.addTab(scope);

                scope.select = function (title) {
                    $location.search('tab', title.toLowerCase());
                    tabsetController.selectTab(scope);
                }

                scope.$watch('selected', function () {
                    if (scope.selected) {
                        tabsetController.setTabTemplate(scope.templateUrl);
                    }
                });
            },
            template:
            '<li ng-class="{active: selected}">' +
            '<a href="" ng-click="select(title)">{{ title }}</a>' +
            '</li>'
        };
    }]);

