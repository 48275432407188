app.directive('popupEdit', function ()
{
    return {
        scope: {
            fieldSet: '=fieldSet'
        },
        templateUrl: '/templates/directives/popup-edit.html',
        size: 'lg',
        replace: true,
        controller: [
            '$scope', '$uibModal',
            function ($scope, $uibModal)
            {
                $scope.doClick = function ()
                {
                    $uibModal.open({
                        templateUrl: '/templates/directives/popup-edit-modal.html',
                        controller: [
                            '$scope',
                            function ($modalScope) {
                                $modalScope.fieldSet = $modalScope.$resolve.fieldSet;
                                $modalScope.close = function () {
                                    $modalScope.$close();
                                }
                            }
                        ],
                        resolve: {
                            fieldSet: function () {
                                return $scope.fieldSet
                            }
                        }
                    })
                }
            }]
    }
});
