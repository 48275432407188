app.controller('ReviewListController', [
    '$scope', '$http', 'SweetAlert', 'DTOptionsBuilder', '$timeout', '$uibModal',
    function ($scope, $http, SweetAlert, DTOptionsBuilder, $timeout, $uibModal)
    {
        $scope.loading = true;

        $scope.status = 'new';

        function filter() {
            $scope.loading = true;
            $timeout(function () {
                $scope.reviews = $scope.all_reviews.filter(function (review) {
                    if ($scope.status === "") return true;
                    return review.status === $scope.status;
                });
                $scope.loading = false;
            });

        }

        $scope.$watch('status', function () {
            if ($scope.all_reviews) {
                filter();
            }
        });

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('iDisplayLength', 25)
            .withOption('oLanguage', {'sEmptyTable': 'No Reviews found'})
            .withBootstrap();

        $http
            .get('review/all?case_type=Case Manager')
            .success(function (reviews)
            {
                $scope.loading = false;
                $scope.all_reviews = reviews;

                $scope.all_reviews.forEach(function (review)
                {
                    if (review.claim.case_information) {
                        review.claim.case_information.basic_information = JSON.parse(review.claim.case_information.basic_information);
                    } else {
                        console.error(review.claim);
                    }
                });

                $scope.reviews = $scope.all_reviews.filter(function (review) {
                    if ($scope.status === "") return true;
                    return review.status === $scope.status;
                });

            }).error(genericErrorHandler);

        $scope.showHistory = function (row) {
            $http
                .get('review/history?case_type=Case Manager&id=' + row.id)
                .success(function (history)
                {
                    $uibModal.open({
                        templateUrl: '/templates/review/history.html',
                        backdrop: 'static',
                        size: 'lg',
                        controller: [
                            '$scope', '$uibModalInstance', 'review', 'history',
                            function ($scope, $uibModalInstance, review, history) {
                                $scope.review = review;
                                $scope.history = history;
                                $scope.close = function () { $uibModalInstance.close() };
                            }],
                        resolve: {
                            review: function ()
                            {
                                return row
                            },
                            history: function ()
                            {
                                return history;
                            }
                        }
                    });
                });
        };

        $scope.markSent = function (review) {
            $http
                .post("review/mark-sent", {id: review.id})
                .success(function () {
                    review.status = "sent";
                    filter();
                });

        };

        function genericErrorHandler(error, code)
        {
            if (code === 422)
            {
                for (var property in error)
                {
                    SweetAlert.swal("Error - " + property, error[property].join(', '), 'error');
                }
            }
            else
            {
                SweetAlert.swal('Error', error.toString(), 'error');
            }
        }
    }]);
